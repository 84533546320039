import React, { useState } from "react";
import { PageHeader, Tag, Space, Card } from "antd";
import moment from "moment";
import Filter from "../../components/Filters/Filter";
import SLTable from "../../components/SLTable/index";
import SLBreadcrumb from "../../components/Breadcrumb/Breadcrumb";

const Deposit = () => {
  const [currency, setCurrency] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf("day").format("X"));
  const [endDate, setEndDate] = useState(moment().startOf("day").add(1, "day").format("X"));
  const [status, setStatus] = useState("all");
  function onSearch() {
    console.log("hi", startDate, endDate, status);
  }

  function onDateChange(date, dateString) {
    console.log("hi", dateString);
  }
  function onStatusChange(s) {
    setStatus(s);
  }

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text) => <a>{text}</a>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>
    },
    {
      title: "MA given Deposit",
      dataIndex: "ma_given_deposit",
      key: "ma_given_deposit"
    },
    {
      title: "MA Available Deposit",
      dataIndex: "ma_avail_deposit",
      key: "ma_available_deposit"
    },
    {
      title: "Cash Balance",
      dataIndex: "cash_balance",
      key: "cash_balance"
    },
    {
      title: "Total Balance",
      dataIndex: "total_balance",
      key: "total_balance"
    },
    {
      title: "Used Rate",
      dataIndex: "used_rate",
      key: "use_rated"
    },
    {
      title: "Deposit/Withdraw",
      dataIndex: "deposit_withdraw",
      key: "deposit_withdraw",
      render: () => (<input />)
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark"
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "actions",
      render: (actions) => (
        <>
          {actions.map((action) => {
            let color = action.length > 5 ? "geekblue" : "green";
            if (action === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={action}>
                {action.toUpperCase()}
              </Tag>
            );
          })}
        </>
      )
    }
  ];

  const data = [
    {
      key: "1",
      name: "John Brown",
      username: "John Brown",
      ma_given_deposit: "32,000,000",
      ma_avail_deposit: "12,000,00",
      cash_balance: "12,000,00",
      total_balance: "60,000,00",
      used_rate: "30%",
      deposit_withdraw: 0,
      remark: "",
      actions: ["MAX", "Submit"]
    },
    {
      key: "2",
      name: "Jim Green",
      username: "Jim Green",
      ma_given_deposit: "42,000,000",
      ma_avail_deposit: "12,000,00",
      cash_balance: "12,000,00",
      total_balance: "60,000,00",
      used_rate: "30%",
      deposit_withdraw: 0,
      remark: "",
      actions: ["MAX", "Submit"]
    },
    {
      key: "3",
      name: "Joe Black",
      username: "Joe Black",
      ma_given_deposit: "32,000,000",
      ma_avail_deposit: "12,000,00",
      cash_balance: "12,000,00",
      total_balance: "60,000,00",
      used_rate: "30%",
      deposit_withdraw: 0,
      remark: "",
      actions: ["MAX", "Submit"]
    }
  ];
  return (
    <div>
      {/* <PageHeader className="site-page-header" title="Deposit" /> */}
      <SLBreadcrumb items={["Filter"]} style={{ marginTop: 15 }} />
      <Filter onSearch={onSearch} onDateChange={onDateChange} onStatusChange={onStatusChange} />
      <SLBreadcrumb items={["Deposit"]} />
      <section>
        <Card>
          <SLTable columns={columns} data={data} bordered />
        </Card>
      </section>

    </div>
  );
};

export default Deposit;
