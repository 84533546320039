/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */

import { Input, Row, Col, DatePicker, Select, Button, Tag, Card } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";
import dotenv from "dotenv";
import moment from "moment";

import utils from "../../helpers/utils";
import HTTPService from "../../services/HTTPService";
import SLTable from "../../components/SLTable/SLTable";
import SLBreadcrumb from "../../components/Breadcrumb";
import Pagination from "../../components/Pagination";
import { useAuth } from "../../hooks/auth";

import "./Report.scss";

dotenv.config();

const API_URL = process.env.REACT_APP_API_SERVER;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_LIMIT_API_CALL = 180;

const Report = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const { info } = useAuth();
  const { t } = useTranslation();
  const [currency, setCurrency] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf("day").add(1, 'second').format("X"));
  const [endDate, setEndDate] = useState(moment().startOf("day").add(1, "day").format("X"));
  const [hasMoreRecords, setHasMoreRecords] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, updateCurrentPage] = useState(0);
  const [datasource, updateDatasource] = useState([]);
  const [status, setStatus] = useState("all");
  const [userID, setUserId] = useState(0);
  const [roundID, setRoundID] = useState(0);
  const [rawData, updateRawData] = useState([]);
  const [agCode, setAGCode] = useState();
  const exportedFileName = useRef("report.csv");
  const exportedData = useRef([]);
  const fetchCount = useRef(1);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sortDirections: ["descend"]
      // width: "3%"
    },
    // {
    //   title: t("PRODUCT_ID"),
    //   dataIndex: "product_id",
    //   key: "product_id",
    //   defaultSortOrder: 'descend',
    //   // sortDirections: ["ascend"],
    //   sorter: (a, b) => a.product_id.props.children - b.product_id.props.children,
    //   // width: "5%",
    //   render(text) {
    //     return {
    //       props: { style: { fontWeight: "bold" } },
    //       children: text
    //     };
    //   }
    // },
    {
      title: t("ROUND_ID"),
      dataIndex: "round_id",
      key: "round_id",
      // width: "8%",
    },
    {
      title: t("ROUND_DETAIL"),
      dataIndex: "round_details",
      key: "round_details",
      // width: "8%",
      render(text) {
        let className = "";
        return {
          props: { className },
          children: text
        };
      }
    },
    // {
    //   title: t("REFERENCE"),
    //   dataIndex: "reference",
    //   key: "reference",
    //   defaultSortOrder: 'descend',
    //   render(text) {
    //     return {
    //       props: { style: { fontWeight: "bold" }},
    //       children: text,
    //     };
    //   }
    // },
    {
      title: t("AG_CODE"),
      dataIndex: "ag_code",
      key: "ag_code"
    },
    {
      title: t("AGENT_PREFIX"),
      dataIndex: "ag_prefix",
      key: "ag_prefix",
      // width: "8%",
      render: (text) => {
        if (text.props.children) {
          return {
            children: text.props.children.replaceAll(',', '')
          }
        } else {
          return "";
        }
      }
    },
    {
      title: t("USER_ID"),
      dataIndex: "username",
      defaultSortOrder: "descend"
    },
    {
      title: t("BALANCE"),
      dataIndex: "balance",
      key: "balance"
    },
    {
      title: t("CLOSING_BALANCE"),
      dataIndex: "closing_balance",
      key: "closing_balance"
    },
    {
      title: t("GAME_ID"),
      dataIndex: "game_id"
      // width: "10%"
    },
    {
      title: t("NO_OF_PAYLINES"),
      dataIndex: "num_payline"
      // width: "8%"
    },
    {
      title: t("WIN_LINES"),
      dataIndex: "win_line"
      // width: "5%"
    },
    {
      title: t("BET"),
      dataIndex: "bet"
      // width: "8%"
    },
    {
      title: t("WIN"),
      dataIndex: "win"
      // width: "8%"
    },
    {
      title: t("DATE"),
      dataIndex: "date",
      sorter: (a, b) => {
        if (!(a.date.props.children || a.date.props.children)) return "";
        return (new Date(a.date.props.children).getTime() - new Date(b.date.props.children).getTime())
      },
      // width: "10%"
    },
    {
      title: t("STATUS"),
      dataIndex: "status"
      // width: "6%"
    }
  ];

  useEffect(() => {
    showDataTable();
    fetchData(!isLoading);
  }, [rawData]);

  async function fetchData(requestAllData) {
    let url = `${API_URL}?action=REPORT&start_date=${startDate}
                &end_date=${endDate}&user_id=${+userID}&round_id=${+roundID}
                &order_by=desc&currency=${currency}&status=${status}`;

    if (agCode !== undefined) {
      url += '&ag_code=' + agCode;
    }

    if (!requestAllData) {
      let limit = fetchCount.current * DEFAULT_LIMIT_API_CALL;
      let offset = limit - DEFAULT_LIMIT_API_CALL;

      url += `&limit=${DEFAULT_LIMIT_API_CALL}&offset=${offset}`;
    }

    const header = { "api-key": info.api_key };
    const res = await HTTPService.get(url, null, header);

    if (requestAllData) {
      manageExportingData(res.data);
    } else {
      setLoading(false);
      setHasMoreRecords(res.data.length > 0);

      fetchCount.current += 1;

      if (res.data && res.data.length) {
        updateRawData((values) => [...values, ...res.data]);
      }
    }
  }

  function onDateChange(date) {
    setStartDate(date[0].startOf("day").format("X"));
    setEndDate(date[1].endOf("day").format("X"));
  }

  function onSearch() {
    fetchCount.current = 1;

    setLoading(true);
    updateRawData([]);
  }

  function onChangePage(page, pageSize) {
    showDataTable(page, pageSize);
  }

  function showDataTable(page, pageSize, totalPages) {
    let bets = [];

    page = page || 1;

    if (rawData && rawData.length) {

      if (page < 1) {
        page = 1;
      } else if (page > totalPages) {
        page = totalPages;
      }

      let limit = page * DEFAULT_PAGE_SIZE;
      let offset = limit - DEFAULT_PAGE_SIZE;

      for (let i = offset; i < limit && i < rawData.length; i++) {
        let bet = rawData[i];
        let id = rawData.length - i;
        let betType = +bet.bet_type;
        let date = moment(+bet.created_at * 1000).format("YYYY/MM/DD HH:mm");
        let statusClassName = "text-black";
        let rowClassName = "text-black";
        let statusText = t("PENDING");

        if (bet.status === 1) {
          statusClassName = "text-green";
          statusText = t("WIN");
        } else if (bet.status === 2) {
          statusClassName = "text-red";
          statusText = t("LOSE");
        } else if (bet.status === 3) {
          rowClassName = "text-red";
          statusText = t("CANCEL");
          betType = 0;
        }

        bets.push({
          key: utils.generateRandomKey(),
          id: <span className={rowClassName}>{id}</span>,
          // product_id: <span className={rowClassName}>{bet.transaction_id}</span>,
          // reference:<Tooltip  title={bet.reference}><span>{bet.reference.slice(0,3) +  "..." + bet.reference.slice(bet.reference.length - 3)}</span></Tooltip> ,
          ag_code: <span className={rowClassName}>{bet.ag_code}</span>,
          ag_prefix: <span className={rowClassName}>{bet.ag_prefix}</span>,
          username: <span className={rowClassName}>{bet.user_id}</span>,
          balance: <span className={rowClassName}>{bet.balance}</span>,
          closing_balance: <span className={rowClassName}>{bet.cash}</span>,
          game_id: <span className={rowClassName}>{bet.game_id}</span>,
          num_payline: <span className={rowClassName}>{bet.no_payline}</span>,
          round_id: bet.round_id,
          round_details: bet.round_details,
          win_line: <span className={rowClassName}>{bet.win_line}</span>,
          bet: <span className={rowClassName}>{bet.bet_amount}</span>,
          win: <span className={rowClassName}>{bet.win_amount}</span>,
          date: <span className={rowClassName}>{date}</span>,
          status: <Tag className={statusClassName}>{statusText}</Tag>
        });
      }
    }
    
    updateCurrentPage(page);
    updateDatasource(bets);
  }

  function onFetchMore() {
    fetchData();
  }

  function onPrevious(totalPages) {
    showDataTable(currentPage - 1, DEFAULT_PAGE_SIZE, totalPages);
  }

  function onNext(totalPages) {
    showDataTable(currentPage + 1, DEFAULT_PAGE_SIZE, totalPages);
  }

  function onLeftExpand(page) {
    updateCurrentPage(page || 1);
  }

  function onRightExpand(page) {
    updateCurrentPage(page || 1);
  }

  function manageExportingData(data) {
    const dataToExport = [];

    dataToExport.push([
      "#",
      t("ROUND_ID"),
      t("ROUND_DETAIL"),
      t("AG_CODE"),
      t("AGENT_PREFIX"),
      t("USER_ID"),
      t("BALANCE"),
      t("CLOSING_BALANCE"),
      t("GAME_ID"),
      t("NO_OF_PAYLINES"),
      t("WIN_LINES"),
      t("BET"),
      t("WIN"),
      t("DATE"),
      t("STATUS")
    ]);

    for (let i = 0; i < data.length; i++) {
      let bet = data[i];
      let id = data.length - i;
      let date = moment(+bet.created_at * 1000).format("YYYY/MM/DD HH:mm");
      let statusText = t("PENDING");

      if (bet.status === 1) {
        statusText = t("WIN");
      } else if (bet.status === 2) {
        statusText = t("LOSE");
      } else if (bet.status === 3) {
        statusText = t("CANCEL");
      }

      dataToExport.push([
        id,
        bet.round_id,
        bet.round_details,
        bet.ag_code,
        bet.ag_prefix,
        bet.user_id,
        bet.balance,
        bet.cash,
        bet.game_id,
        bet.no_payline,
        bet.win_line,
        bet.bet_amount,
        bet.win_amount,
        date,
        statusText
      ]);
    }

    exportedData.current = dataToExport;
    exportedFileName.current = `report-${ moment(startDate * 1000).format("YYYY_MM_DD") }--${ moment(endDate * 1000).format("YYYY_MM_DD") }.csv`;
  }

  return (
    <div key="report" id="report">
      {/* <PageHeader className="site-page-header" title="Report" /> */}

      <SLBreadcrumb items={[t("FILTER")]} style={{ marginTop: 15 }} />

      <Card id="filter">
        <Row gutter={[9, 9]}>

          <Col className="gutter-row" span={7}>
            <RangePicker key="date-picker" onChange={onDateChange} size="small" defaultValue={[moment(), moment()]} />
          </Col>

          <Col className="gutter-row" span={3}>
            <Input
              onChange={(e) => {
                setAGCode(e.target.value);
              }}
              placeholder={t('AG_CODE')}
              size="small"
              key="user-id"
            />
          </Col>

          <Col className="gutter-row" span={3}>
            <Input
              onChange={(e) => {
                setUserId(e.target.value);
              }}
              placeholder={t('USER_ID')}
              size="small"
              key="user-id"
            />
          </Col>

          {/* <Col className="gutter-row" span={3}>
            <Input
                onChange={(e) => {
                  setReference(e.target.value);
                }}
                placeholder={t("REFERENCE")}
                size="small"
                key="reference"
              />
          </Col> */}

          <Col className="gutter-row" span={3}>
            <Input
              onChange={(e) => {
                setRoundID(e.target.value);
              }}
              placeholder={t("ROUND_ID")}
              size="small"
              key="round-id"
            />
          </Col>

          <Col className="gutter-row" span={3}>
            <Select
              onChange={(st) => {
                setStatus(st);
              }}
              key="selected"
              defaultValue="all"
              size="small"
              id="status"
              style={{ width: 100 }}>
              <Option value="all">{t("ALL")}</Option>
              <Option value="1">{t("WIN")}</Option>
              <Option value="2">{t("LOSE")}</Option>
              <Option value="3">{t("CANCEL")}</Option>
            </Select>
          </Col>

          <Col span={2} style={{ marginTop: "2px" }}>
            <button className="report__btn" onClick={onSearch}>
              { isLoading ? <LoadingOutlined /> : <SearchOutlined /> }
              <label>{t("SEARCH")}</label>
            </button>
          </Col>

          {
            exportedData.current && exportedData.current.length ? (
              <Col span={2} style={{ marginTop: "2px", marginLeft: "3px" }}>
                <CSVLink 
                  data={exportedData.current}
                  filename={exportedFileName.current} 
                  className="report__btn">{t("EXPORT")}
                </CSVLink>
              </Col>
            ) : null
          }
        </Row>
        {/* <Row gutter={[24, 24]}>
          <Col className="gutter-row" span={5}>
            <Input
              onChange={(e) => {
                setOpName(e.target.value);
              }}
              placeholder="operator name"
              size="small"
              key="operator-name"
            />
          </Col>
          <Col className="gutter-row" span={4}>
            <Input
              onChange={(e) => {
                setUserId(e.target.value);
              }}
              placeholder="user ID"
              size="small"
              key="user-id"
            />
          </Col>
        </Row> */}
      </Card>

      <SLBreadcrumb items={[t("REPORT"), t("HISTORY")]} />

      <section id="content" key={utils.generateRandomKey()}>
        <Card>
          <SLTable
            bordered
            size="small"
            columns={columns}
            data={datasource}
            className="report"
            pagination={false}
          />

          <Pagination
            current={currentPage}
            isLoading={isLoading}
            showMoreButton={hasMoreRecords}
            pageSize={DEFAULT_PAGE_SIZE}
            total={rawData.length}
            onChange={onChangePage}
            onFetchMore={onFetchMore}
            onLeftExpand={onLeftExpand}
            onRightExpand={onRightExpand}
            onPrevious={onPrevious}
            onNext={onNext} />
        </Card>
      </section>
    </div>
  );
};

export default Report;
