/* eslint-disable no-return-assign */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import { PageHeader, Table, Space, Modal, Form, Input, Button, Alert, Typography } from "antd";
import Helpers from "../../helpers/utils";
import State from "../../data";

const Setting = () => {
  const [settings, updateSettings] = useState(State.settings);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingSetting, setEditingSetting] = useState();
  const [modalKey, setModalKey] = useState(Helpers.generateRandomKey());
  const [formAlertStatus, setFormAlertStatus] = useState([false, false]); // isFormUpdate, updatedStatus
  const [form] = Form.useForm();

  const showModal = (row) => {
    setEditingSetting(row);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setFormAlertStatus([false, false]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFormAlertStatus([false, false]);
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 80,
      render: (text) => text
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (text) => text
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text) => text
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      render: (row) => (
        <Space size="middle">
          <Typography.Link onClick={() => showModal(row)}>Edit</Typography.Link>
        </Space>
      )
    }
  ];

  const data = [
    {
      key: "numberOfReels",
      no: 1,
      name: "Number of Reels",
      value: settings.number_of_reels,
      action: [
        {
          label: "Number Of Reels",
          name: "number_of_reels",
          value: settings.number_of_reels
        }
      ]
    },
    {
      key: "numberOfRows",
      no: 2,
      name: "Number of Rows",
      value: settings.number_of_rows,
      action: [
        {
          label: "Number Of Rows",
          name: "number_of_rows",
          value: settings.number_of_rows
        }
      ]
    },
    {
      key: "freeSpins",
      no: 3,
      name: "Free Spins",
      value: settings.free_spins.toString(),
      action: [
        {
          label: "Free Spins",
          name: "free_spins",
          value: settings.free_spins
        }
      ]
    }
  ];

  const onSubmit = (values) => {
    const newSettings = settings;

    Object.keys(values).map((key) => {
      if (key === "free_spins") {
        newSettings[key] = values[key].split(",").filter((value) => +value);
      } else {
        newSettings[key] = +values[key];
      }

      return 0;
    });

    updateSettings(newSettings);
    setFormAlertStatus([true, true]);
  };

  const getFormControls = () => {
    const formControls = editingSetting.reduce((total, setting) => {
      // eslint-disable-next-line no-param-reassign
      total[setting.name] = setting.value;
      return total;
    }, {});

    return formControls;
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="Settings" />

      <section>
        <Table bordered size="small" columns={columns} dataSource={data} pagination={false} />
      </section>

      <Modal
        key={modalKey}
        title="Edit Setting"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        afterClose={() => setModalKey(Helpers.generateRandomKey)}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onSubmit(values);
                })
                .catch(() => {
                  // console.log("Validate Failed:", info);
                  setFormAlertStatus([true, false]);
                });
            }}>
            Submit
          </Button>
        ]}>
        {editingSetting && editingSetting.length && (
          <>
            {formAlertStatus[0] &&
              (formAlertStatus[1] ? (
                <Alert message="Success" type="success" showIcon />
              ) : (
                <Alert message="Failed" type="error" showIcon />
              ))}
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={getFormControls(editingSetting)}
              autoComplete="off"
              style={{ marginTop: "1em" }}>
              {editingSetting.map((column) => (
                <Form.Item
                  key={column.name}
                  label={column.label}
                  name={column.name}
                  rules={[{ required: true, message: "Please input number of reels" }]}>
                  <Input name={column.name} />
                </Form.Item>
              ))}
            </Form>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Setting;
