import React, { useState } from "react";
import { PageHeader, Tag, Space, Card, Badge } from "antd";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import Filter from "../../components/Filters/Filter";
import SLTable from "../../components/SLTable/index";
import SLBreadcrumb from "../../components/Breadcrumb";

const AcctDeposit = () => {
  const [currency, setCurrency] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf("day").format("X"));
  const [endDate, setEndDate] = useState(moment().startOf("day").add(1, "day").format("X"));
  const [status, setStatus] = useState("all");
  function onSearch() {
    console.log("hi", startDate, endDate, status);
  }

  function onDateChange(date, dateString) {
    console.log("hi", dateString);
  }
  function onStatusChange(s) {
    setStatus(s);
  }

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text) => <a>{text}</a>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (actions) => (
        <>
          {actions.map((action) => {
            let color = action.length > 5 ? "geekblue" : "green";
            if (action === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={action}>
                {action}
              </Tag>
            );
          })}
        </>
      )
    },
    {
      title: "Deposit",
      dataIndex: "deposit",
      key: "deposit"
    },
    {
      title: "MA Available Deposit",
      dataIndex: "ma_avail_deposit",
      key: "ma_avail_deposit"
    },
    {
      title: "AG",
      dataIndex: "ag",
      key: "ag"
    },
    {
      title: "Member",
      dataIndex: "member",
      key: "member"
    },
    {
      title: "Deposit/Withdraw",
      dataIndex: "deposit_withdraw",
      key: "deposit_withdraw",
      render: (text) => (<Badge count={text} size="small" />)
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      render: (time) => (moment().format("l"))
    }

  ];

  const data = [
    {
      key: "1",
      name: "John Brown",
      username: "88990",
      edit: [<EditOutlined />],
      deposit: "12,000,00",
      ma_avail_deposit: "12,000,00",
      ag: "60,000,00",
      member: "3(0)",
      deposit_withdraw: "No",
      last_login: ""
    },
    {
      key: "2",
      name: "Jim Green",
      username: "88990",
      edit: [<EditOutlined />],
      deposit: "12,000,00",
      ma_avail_deposit: "12,000,00",
      ag: "60,000,00",
      member: "3(0)",
      deposit_withdraw: "No",
      last_login: ""
    },
    {
      key: "3",
      name: "Joe Black",
      username: "88990",
      edit: [<EditOutlined />],
      deposit: "12,000,00",
      ma_avail_deposit: "12,000,00",
      ag: "60,000,00",
      member: "3(0)",
      deposit_withdraw: "No",
      last_login: ""
    }
  ];
  return (
    <div>
      {/* <PageHeader className="site-page-header" title="Deposit" /> */}
      <SLBreadcrumb items={["Filter"]} style={{ marginTop: 15 }} />
      <Filter onSearch={onSearch} onDateChange={onDateChange} onStatusChange={onStatusChange} />
      <SLBreadcrumb items={["Account", "Deposit"]} />
      <section>
        <Card>
          <SLTable columns={columns} data={data} bordered />
        </Card>
      </section>

    </div>
  );
};

export default AcctDeposit;
