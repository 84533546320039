/* eslint-disable no-script-url */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect, useCallback } from "react";
import { Tag, Space, Input, InputNumber, Popconfirm, Form, Typography, Card } from "antd";
import State from "../../data";
import SLTable from "../../components/SLTable/SLTable";
import "./PayTable.scss";
import SLBreadcrumb from "../../components/Breadcrumb";

const loopReel = (cb) => {
  for (let i = 0; i < State.settings.number_of_reels; i++) {
    cb(i + 1);
  }
};

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true, message: `Please input ${title}` }]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PayTable = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [dataSource, updateDataSource] = useState([]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];

        Object.keys(row).map((prop) => {
          row[prop] = +row[prop];
          return row;
        });

        newData.splice(index, 1, { ...item, ...row });
        updateDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        updateDataSource(newData);
        setEditingKey("");
      }
    } catch (err) {
      // console.log("Validate Failed:", err);
    }
  };

  const getPaymentsBySymbolKey = (key) => {
    const paytable = State.paytables.filter((symbol) => symbol.key === key);

    if (paytable && paytable.length) return paytable[0].payments;

    return [];
  };

  const createRow = useCallback((rowNumber, symbol) => {
    const newRow = {
      key: rowNumber,
      symbol: symbol.name
    };

    loopReel((reelNumber) => {
      newRow[`reel${reelNumber}`] = getPaymentsBySymbolKey(symbol.key)[reelNumber - 1] || 0;
    });

    updateDataSource((arr) => [...arr, newRow]);
  });

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      // width: 200,
      render: (text) => text
    }
  ];

  loopReel((reelNumber) => {
    columns.push({
      title: `Reel ${reelNumber}`,
      dataIndex: `reel${reelNumber}`,
      key: `reel${reelNumber}`,
      editable: true,
      render: (text) => <a>{text}</a>
    });
  });

  columns.push({
    title: "Action",
    dataIndex: "action",
    // width: 150,
    render: (_, record) => {
      const editable = isEditing(record);

      return editable ? (
        <span>
          <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
            Save
          </Typography.Link>
          <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <a>Cancel</a>
          </Popconfirm>
        </span>
      ) : (
        <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record)}>
          Edit
        </Typography.Link>
      );
    }
  });

  useEffect(() => {
    State.symbols.forEach((symbol, index) => {
      createRow(index + 1, symbol);
    });
  }, []);

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <div className="paytable__page">
      {/* <PageHeader
        className="site-page-header"
        title="Pay Table"
        subTitle="Configure pay table for the list of payouts"
      /> */}
      <SLBreadcrumb items={["Settings", "PayTable"]} style={{ marginTop: 15 }} />
      <Space style={{ float: "right", paddingBottom: "0.5em" }}>
        <div className="tag-group">
          <Tag>
            <span>Number Of Symbols</span> - <b>{State.symbols.length}</b>
          </Tag>
        </div>
      </Space>

      <section>
        <Card>
          <Form form={form} component={false}>
            <SLTable
              bordered
              size="small"
              components={{ body: { cell: EditableCell } }}
              data={dataSource}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
          />
          </Form>
        </Card>
      </section>
    </div>
  );
};

export default PayTable;
