import "./Select.scss";

const Select = ({ defaultValue, style, list, disabled, onChange }) => {
  return (
    <select className="app-select" style={style} disabled={disabled} onChange={onChange} value={defaultValue}>
      {
        list.length && list.map(item => {
          return <option key={item.value} value={item.value}>{item.label}</option>;
        })
      }
    </select>
  );
};

export default Select;