import { useState, useEffect } from "react";
import moment from "moment";

const LiveDatetime = (props) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setInterval(() => {
      setDate(new Date());
    }, 1000);
  }, []);

  const getDate = () => {
    return moment(date).format("YYYY/MM/DD dddd HH:mm:ss z Z");
  };

  return (
    <div {...props}>{getDate()}</div>
  );
};

export default LiveDatetime;
