export default {
  APP_NAME: "ហ្គេម Slot",
  EN: "English",
  KO: "한국어",
  KH: "ខ្មែរ",
  LANGUAGE: "​ភាសា",
  USER_DETAILS: "ព័ត៌មានលម្អិតអ្នកប្រើប្រាស់",
  CASINO_PRODUCT: "ផលិតផលកាស៊ីណូ (%)",
  SLOT_PRODUCT: "ផលិតផល Slot (%)",
  SLOTS: "Slots",
  PREFIX: "បុព្វបទ",
  USERNAME: "ឈ្មោះ​អ្នកប្រើប្រាស់",
  MA_NAME: "ឈ្មោះ MA",
  PASSWORD: "ពាក្យសម្ងាត់",
  STATUS: "ស្ថានភាព",
  LEVEL: "កម្រិត",
  ACTIVE: "សកម្ម",
  INACTIVE: "អសកម្ម",
  CREATE: "បង្កើត",
  LOADING: "សូមរង់ចាំ",
  SUPER_MASTER_AGENT: "Super Master Agent",
  MASTER_AGENT: "Master Agent",
  AGENT: "Agent",
  LOGOUT: "ចាកចេញ",
  CHANGE_PASSWORD: "ផ្លាស់ប្តូរពាក្យសម្ងាត់",
  ACCOUNT_LIST: "បញ្ជីគណនី",
  FILTER: "តម្រង",
  INVALID_DATA: "គ្មានអនុញ្ញាត",
  ALL: "ទាំងអស់",
  OPEN: "បើក",
  CLOSED: "បិទ",
  SUBMIT: "ដាក់ស្នើ",
  YES: "បាទ/ចាស",
  NO: "ទេ",
  LOCK: "ចាក់សោ",
  UNLOCK: "ដោះសោ",
  LEAVE_BLANK_IF_NO_CHANGE: "សូម​ទុក​វាឱ្យ​នៅ​ទទេ បើ​មិន​ចាំបាច់​មាន​ការ​ផ្លាស់ប្ដូរ",
  EDIT: "កែសម្រួល",
  USERNAME: "ឈ្មោះ​អ្នកប្រើប្រាស់",
  AG: "ភ្នាក់ងារ",
  SUSPEND: "ផ្អាក",
  NAME: "ឈ្មោះ",
  LAST_LOGIN_TIME: "ពេលវេលាចូលចុងក្រោយ",
  LAST_LOGIN_IP: "IP ចូលចុងក្រោយ",
  NATION: "ជាតិ",
  REGISTERED_TIME: "ពេលវេលាចុះឈ្មោះ",
  MEMO: "អនុស្សរណៈ",
  AGENT_PREFIX: "AG Prefix",
  NO_RECORD_FOUND: "រកមិនឃើញកំណត់ត្រាទេ!",
  COUNTRY: "ប្រទេស",
  MEMBER_LIST: "បញ្ជីសមាជិក",
  ID: "លេខសម្គាល់",
  MEMBER_ID: "លេខសម្គាល់សមាជិក",
  ACTION: "សកម្មភាព",
  KICK_PLAYER: "បញ្ឈប់អ្នកលេង",
  PLAYABLE: "អាចលេងបាន",
  URL: "URL",
  API_LIST: "API List",
  METHOD: "Method",
  DESCRIPTION: "ការពិពណ៌នា",
  COPIED: "ចម្លង",
  SEARCH: "ស្វែងរក",
  MAILBOX: "ប្រអប់សំបុត្រ",
  CREATE_MAIL: "បង្កើតសំបុត្រ",
  WRITE_YOUR_MESSAGE_HERE: "សរសេរសាររបស់អ្នកនៅទីនេះ...",
  SUBJECT: "ប្រធានបទ",
  SEND: "ផ្ញើ",
  DATE: "Date",
  UPDATE: "Update",
  PLEASE_FILL_OUT_THE_FORM: "សូមបំពេញទម្រង់បែបបទ",
  HOME: "Home",
  MEMBER_MGMT: "Member Mgmt",
  DEPOSIT: "Deposit",
  DEPOSIT_INFO: "Deposit Info",
  REPORTS: "Reports",
  ACCOUNT: "Account",
  SIGN_OUT: "Sign Out",
  NEW_MA: "New MA",
  KEY_GRAPH: "Key Graph",
  INFORMATION: "Information",
  MAINTENANCE_STATUS: "Maintence Status",
  CASH_BALANCE: "Cash Balance",
  TOTAL_BALANCE: "Total Balance",
  TOTAL_MA : "Total MA",
  TOTAL_AG: "Total AG",
  TOTAL_MEMBER: "Total Member",
  FILTER: "Filter",
  INVALID_PREFIX: "Invalid prefix",
  USER_ID: "User ID",
  PROFILE: "Profile",
  API_KEY: "API Key",
  AG_CODE: "AG Code",
  AG_ID: "AG ID",
  ENABLE: "Enable",
  DISABLE: "Disable",
  PLAYING: "Playing",
  SELECT_GAME: "select game",
  SUMMARY: "Summary",
  REPORT:"Report",
  TOTAL: "Total",
  WINLOSS: "Winloss",
  WAGER: "Wager",
  TURNOVER: "Turnover",
  RTP: "RTP",
  HISTORY:"History",
  PRODUCT_ID:"Product ID",
  REFERENCE:"Reference",
  BALANCE:"Balance",
  CLOSING_BALANCE:"Closing Balance",
  GAME_ID:"Game ID",
  NO_OF_PAYLINES:"No. of Paylines",
  BET_TYPE:"Bet Type",
  WIN_LINES:"Win Lines",
  BET:"Bet",
  WIN : "Win",
  LOSE: "Lose",
  CANCEL: "Cancel",
  REFERENCE: "Reference",
  ONLINE: "Online",
  OFFLINE: "Offline",
  LIVE_REPORT: "Live Report",
  ROUND_ID: "Round ID",
  ROUND_DETAIL: "Round Details",
  PENDING: "Pending",
  MORE: "More",
  EXPORT: "Export",
};
