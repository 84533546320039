/* eslint-disable no-async-promise-executor */

import HTTPService from "../services/HTTPService";
import countries from "../countries.json";
import config from "../config";
import api from "./api";

export default {
  generateRandomKey: () => {
    const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const randCount = 10;
    const result = [];

    for (let i = 0; i < randCount; i++) {
      result.push(str.charAt(Math.floor(Math.random() * str.length)));
    }

    return result.join("");
  },

  login: (values) => new Promise(async (resolve, reject) => {
    try {
      const response = await HTTPService.post(`${config.app.serverUrl}${api.LOGIN}`, values);

      resolve(response);
    } catch (err) {
      reject(err);
    }
  }),

  getFlagUrl: (country) => `https://countryflagsapi.com/png/${country}`,

  numberFormatAsCurrency: (amount) => {
    amount = "" + amount;
    return amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },

  getCountries: () => countries,

  getCountryByCode: (code) => countries.filter((country) => country.code.toLowerCase().trim() === code.toLowerCase().trim()),
};
