import { useState } from "react";
import SLBreadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import SLTable from "../../components/SLTable";
import {Card,Input,Form, Alert} from "antd";
import HTTPService from "../../services/HTTPService";
import api from "../../helpers/api";
import { useAuth } from "../../hooks/auth";
import config from "../../config";
import "./style.scss"
const ChangePassword = () => {
  const {t} = useTranslation();
  const [currentPass,setCurrentPass] = useState("");
  const [newPass,setNewPass] = useState("");
  const [confirmPass,setConfirmPass] = useState("");
  const [form] = Form.useForm();
  const {info} = useAuth();
  const [status,setStatus] = useState();
  const [message,setMessage] = useState("");

  const onReset = () => {
    setCurrentPass("");
    setNewPass("");
    setConfirmPass("");
    setStatus(null);
    setMessage('');
    form.resetFields();
  }

  const OnUpdate = () => {
    const data = {
      username: info.username,
      currentPass : currentPass,
      newPass: newPass
    }

    const response = HTTPService.post(`${config.app.serverUrl}${api.RESETPASSWORD}` ,data,{sid: sessionStorage.sid} );
    response.then((result) => {
       setStatus(result.status);
       setMessage(result.message);
    })
  }

  const handleClose = () =>{
    setStatus(null);
    setMessage("");
  }

  const columns = [
    {
      title: 'name',
      dataIndex: "name",
      sortDirections: ["descend"],
      width: 150
    },
    {
      title: "Product ID",
      dataIndex: "input",
      width: 150

    }];

    const data = [
      {
        key: 'currenct-pass',
        name: 'Current Password',
        input: <Form.Item name="Current-Pass"  rules={[{required:true,message:'Please Enter Current Password'} ,{min:6,message:'min password  6 digit'}]}><Input id="current-pass" value={currentPass} onInput={(e) => {setCurrentPass(e.target.value)}}  type="password"/></Form.Item>

      },
      {
        key: 'new-pass',
        name: 'New Password',
        input: <Form.Item name="new-pass" rules={[{required:true,message:"Please Enter your Password"},{min:6,message:'min password  6 digit'}]}><Input id="new-pass" value={newPass} onInput={(e) => {setNewPass(e.target.value)} }  type="password"/></Form.Item>

      }, {
        key: 'confirm-pass',
        name: 'Confirm New Password',
        input: <Form.Item name="confirm-pass" dependencies={["new-pass"]} hasFeedback rules={[{required:true,message:"Please Confirm Password"},{min:6,message:'min password  6 digit'}, ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('new-pass') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
          },
        }),]}><Input id="confirm-pass" value={confirmPass} onInput={(e) => {setConfirmPass(e.target.value)}}  type="password"/></Form.Item>

      },
      {
        key: 'event',
        name: <button type="submit">Update</button>,
        input:<button onClick={onReset}>reset</button>,

      }

    ]

    const MessageAlert = ({ type, description }) => {
      let Component = null;

      if (type === "success") {
        Component = <Alert message="Success" type="success" showIcon closable description={description} afterClose={handleClose} />;
      } else if (type === "failed") {
        Component = <Alert message="Failed" type="error" showIcon closable description={description} afterClose={handleClose} />;
      }

      return Component;
    };

  return(

    <div id="change-pass">
      <SLBreadcrumb items={[t('CHANGE_PASSWORD')]} style={{marginTop: 15,width: 600}}></SLBreadcrumb>

      <section id="content">
        <Card style={{width: 600}}>
         <Form onFinish={OnUpdate} form={form}>

          <SLTable
              bordered={true}
              size="small"
              columns={columns}
              data={data}
              className="change-password"
              pagination={false}
            />
         </Form>

         <MessageAlert type={status} description={message}></MessageAlert>
        </Card>
        </section>
    </div>

  );
}



export default ChangePassword;
