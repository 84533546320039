import { Alert } from "antd";
import constants from "../../../helpers/constants";

const state = constants.state;

const MessageAlert = ({ type, description }) => {
  let Component = null;

  if (type === state.SUCCESS) {
    Component = <Alert message="Success" type="success" showIcon description={description} />;
  } else if (type === state.FAILED) {
    Component = <Alert message="Failed" type="error" showIcon description={description} />;
  }
  
  return Component;
};

export default MessageAlert;