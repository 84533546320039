import { useState } from "react";
import { NavLink } from "react-router-dom";

import "./Dropdown.scss";

const Dropdown = ({ title, caption, displayItem, list, onClickItem, isOpen, setOpen }) => {
  const onShowList = () => {
    setOpen(!isOpen);
  };

  return (
    <div className="app-dropdown" onClick={onShowList}>
      <div className="app-dropdown__display-item">
        <span className="app-dropdown__title">{title}</span>
        {displayItem}
      </div>
      {
        isOpen && (
          <div className="app-dropdown__popup">
            <header>{caption}</header>
            <ul className="app-dropdown__list">
              {
                list.map((item) => (
                  <>
                  {item.url ? (<li key={item.value} value={item.value} onClick={onClickItem.bind(null, item.value)}>
                  <NavLink to={item.url}>
                    <span className="app-dropdown__item-icon">{item.icon}</span>
                    <span>{item.label}</span>
                  </NavLink></li>) : (<li key={item.value} value={item.value} onClick={onClickItem.bind(null, item.value)}>
                    <span className="app-dropdown__item-icon">{item.icon}</span>
                    <span>{item.label}</span>
                  </li>)}</>
                ))
              }
            </ul>
          </div>
        )
      }
    </div>
  );
};

export default Dropdown;
