import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { Card, Badge, Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { EditOutlined, SaveOutlined, RollbackOutlined } from "@ant-design/icons";
import styled from "styled-components";

import HTTPService from "../../services/HTTPService";
import Filter from "../../components/Filters/Filter";
import SLTable from "../../components/SLTable/index";
import SLBreadcrumb from "../../components/Breadcrumb/Breadcrumb";
import utils from "../../helpers/utils";
import constants from "../../helpers/constants";
import { useAuth } from "../../hooks/auth";

import "./AccountList.scss";
import App from "../../App";

const API_URL = process.env.REACT_APP_API_SERVER;

const state = constants.state;

const statusType = {};

statusType[statusType["open"] = 1] = "open";
statusType[statusType["closed"] = 0] = "closed";

const suspendType = {};

suspendType[suspendType["yes"] = 1] = "yes";
suspendType[suspendType["no"] = 0] = "no";

const lockType = {};

lockType[lockType["lock"] = 1] = "lock";
lockType[lockType["unlock"] = 0] = "unlock";

const fetchData = async (params, header, cb) => {
  const response = await HTTPService.get(API_URL, params, header);
  return cb(response);
};

const postData = async (params, header, cb) => {
  const response = await HTTPService.post(API_URL, params, header);
  return cb(response);
};

const BreadcrumbItem = styled.a`
  color: #fc0 !important;
  font-weight: bold;

  &:hover {
    color: red !important;
  }
`;

const FormInput = styled(Input)`
  max-width: 150px;
`;

const BadgeColumn = styled(Badge)`
  .ant-badge-count {
    background-color: ${props => props.bg};
  }
`;

const AccountList = () => {
  const { t } = useTranslation();
  const { info: user } = useAuth();
  const [dataSource, updateDataSource] = useState([]);
  const [breadcrumbList, updateBreadcrumbList] = useState([]);
  const [status, setStatus] = useState("all");
  const originalDataSource = useRef();
  const searchRef = useRef();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [message, updateMessage] = useState({ type: state.NORMAL, description: "" });
  const [loading, setLoading] = useState(true);

  const url = new URLSearchParams(window.location.search);
  const tier = url.get("tier") || '';
  const username = tier || user.username;
  const accessDenied = tier.length && tier.length < user.username.length;

  const isEditing = (record) => record.username === editingKey;

  useEffect(() => {
    if (!user.parent || user.username.length < tier) {
      fetchData({ action: "GET_BREADCRUMB_BY_USERNAME", username }, { "api-key": user.api_key }, (res) => {
        if (res && res.status) {
          const data = res.data.concat(username);
          const list = data.reduce(function (total, parent) {
            if (parent.length >= user.username.length) {
              total.push(<BreadcrumbItem href={"/accounts/downline?tier=" + parent}>{parent}</BreadcrumbItem>);
            }

            return total;
          }, []);

          updateBreadcrumbList((old) => [...old, ...list]);
        }
      });
    } else {
      updateBreadcrumbList((old) => [
        ...old,
        <BreadcrumbItem href={"/accounts/downline?tier=" + username}>{username}</BreadcrumbItem>
      ]);
    }

    if (!accessDenied) {
      const params = { action: "GET_ACCOUNT_LIST", parent_username: username };

      fetchData(params, { "api-key": user.api_key }, (res) => {
        if (res && res.status) {
          let rowNumber = 1;

          const accounts = res.data.map(row => {
            return {
              id: rowNumber++,
              key: row.id,
              username: row.username,
              ag: row.ag_count,
              status: row.status ? t("OPEN") : t("CLOSED"),
              suspend: row.suspend ? t("YES") : t("NO"),
              lock: row.lock ? t("LOCK") : t("UNLOCK"),
              user_id: row.name,
              last_login_time: row.last_login_time * 1000,
              last_login_ip: row.last_login_ip,
              registered_time: row.created_at * 1000,
              memo: row.memo
            }
          });

          originalDataSource.current = accounts;
          updateDataSource(accounts);
          setLoading(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (message.type !== state.NORMAL) {
      let timer = setTimeout(() => {
        clearTimeout(timer);
        updateMessage({ type: state.NORMAL, description: "" });
      }, 5000);
    }
  }, [message]);

  const onEditRow = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.username);
    updateMessage({
      type: state.NORMAL
    });
  };

  const onSaveRecord = async (record) => {
    if (record) {

      if (record.password && record.password.length) {
        const response = await utils.login({ username: user.username, password: record.password });

        if (response && response.status === "success") {
          if (isNaN(parseInt(record.status))) {
            record.status = +statusType[record.status.toLowerCase()];
          }

          if (isNaN(parseInt(record.suspend))) {
            record.suspend = +suspendType[record.suspend.toLowerCase()];
          }

          if (isNaN(parseInt(record.lock))) {
            record.lock = +lockType[record.lock.toLowerCase()];
          }

          postData({
            action: "UPDATE_ACCOUNT",
            status: record.status,
            suspend: record.suspend,
            lock: record.lock,
            memo: record.memo,
            username: editingKey
          }, { "api-key": user.api_key }, (res) => {
            if (res && res.status) {
              const updatedDataSource = dataSource.map((data) => {
                if (data.username === editingKey) {
                  data.status = +record.status ? "Open" : "Close";
                  data.suspend = +record.suspend ? "Yes" : "No";
                  data.lock = +record.lock ? "Lock" : "Unlock";
                  data.memo = record.memo;
                }

                return data;
              });

              updateDataSource(updatedDataSource);
              setEditingKey("");

              updateMessage({
                type: state.SUCCESS,
                description: res.description
              });
            } else {
              updateMessage({
                type: state.FAILED,
                description: res.description
              });
            }
          });
        } else {
          updateMessage({
            type: state.FAILED,
            description: "Incorrect password"
          });
        }

      } else {
        updateMessage({
          type: state.FAILED,
          description: "Please input your password."
        });
      }

    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("AG_CODE"),
      dataIndex: "username",
      key: "username",
      render: (text) => <a href={"/accounts/downline?tier=" + text} className="table-row__col--username">{text}</a>
    },
    {
      title: t("AG"),
      dataIndex: "ag",
      key: "ag",
    },
    {
      title: t("STATUS"),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const bgColor = status.toLowerCase() === "open" ? "#4DBD74" : "#F9C10A";
        return <BadgeColumn count={status} bg={bgColor} />;
      },
      onCell: (record) => ({
        record: record,
        dataIndex: "status",
        editing: isEditing(record),
      })
    },
    {
      title: t("SUSPEND"),
      dataIndex: "suspend",
      key: "suspend",
      render: (suspend) => {
        const bgColor = suspend.toLowerCase() === "no" ? "#4DBD74" : "#F9C10A";
        return <BadgeColumn count={suspend} bg={bgColor} />;
      },
      onCell: (record) => ({
        record: record,
        dataIndex: "suspend",
        editing: isEditing(record)
      })
    },
    {
      title: t("LOCK"),
      dataIndex: "lock",
      key: "lock",
      render: (lock) => {
        const bgColor = lock.toLowerCase() === "unlock" ? "#4DBD74" : "#F9C10A";
        return <BadgeColumn count={lock} bg={bgColor} />;
      },
      onCell: (record) => ({
        record: record,
        dataIndex: "lock",
        editing: isEditing(record)
      })
    },
    {
      title: t("AG_ID"),
      dataIndex: "user_id",
      key: "user_id"
    },
    {
      title: t("LAST_LOGIN_TIME"),
      dataIndex: "last_login_time",
      key: "last_login_time",
      render: (time) => time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : ''
    },
    {
      title: t("LAST_LOGIN_IP"),
      dataIndex: "last_login_ip",
      key: "last_login_ip"
    },
    {
      title: t("REGISTERED_TIME"),
      dataIndex: "registered_time",
      key: "registered_time",
      render: (time) => time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : ''
    },
    {
      title: t("MEMO"),
      dataIndex: "memo",
      key: "memo",
      onCell: (record) => ({
        record: record,
        dataIndex: "memo",
        editing: isEditing(record)
      })
    },
    {
      title: t("EDIT"),
      key: "edit",
      dataIndex: "edit",
      render: (_, record) => {
        const editable = isEditing(record);

        return (
          editable ? (
            <>
              <Button htmlType="submit" style={{ marginRight: "2px" }}><SaveOutlined /></Button>
              <Button onClick={onEditRow}><RollbackOutlined /></Button>
            </>
          ) : (
            <Button onClick={onEditRow.bind(null, record)}><EditOutlined /></Button>
          )
        );
      }
    }
  ];

  const onStatusChange = (evt) => {
    setStatus(evt.target.value);
  };

  const handleSubmit = () => {
    let inputValue = searchRef.current.input.value;

    const newDataSource = originalDataSource.current
      .filter((data) => {
        return status === 'all' || data.status.toLowerCase() === statusType[status].toLowerCase();
      })
      .filter((data) => {
        let found = true;

        inputValue = inputValue.toLowerCase().trim();

        found = data.last_login_ip && data.last_login_ip.indexOf(inputValue) !== -1;
        found = found || data.user_id.toLowerCase().indexOf(inputValue) !== -1;
        found = found || data.username.toLowerCase().indexOf(inputValue) !== -1;
        found = found || moment(data.last_login_time).format("YYYY-MM-DD HH:mm:ss").indexOf(inputValue) !== -1;
        found = found || moment(data.registered_time).format("YYYY-MM-DD HH:mm:ss").indexOf(inputValue) !== -1;

        return found;
      });

    updateDataSource(newDataSource);
  };

  const EditableStatusCell = ({ record, editing, dataIndex, children, ...restProps }) => {
    let EditableNode = null;

    if (dataIndex === "status") {
      EditableNode = (
        <div className="table-cell table-cell__status">
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}>
            <App.RadioGroup direction="row" fontSize="12px">
              {
                record[dataIndex].toLowerCase() === "open" ? (
                  <>
                    <App.Radio id="status-open" name={dataIndex} label={t("OPEN")} value={1} defaultChecked />
                    <App.Radio id="status-closed" name={dataIndex} label={t("CLOSED")} value={0} />
                  </>
                ) : (
                  <>
                    <App.Radio id="status-open" name={dataIndex} label={t("OPEN")} value={1} />
                    <App.Radio id="status-closed" name={dataIndex} label={t("CLOSED")} value={0} defaultChecked />
                  </>
                )
              }
            </App.RadioGroup>
          </Form.Item>
          <Form.Item
            type="password"
            label={t("PASSWORD")}
            name="password"
            help={t("LEAVE_BLANK_IF_NO_CHANGE")}
            className="table-cell__status-password">
            <Input.Password />
          </Form.Item>
        </div>
      );
    } else if (dataIndex === "suspend") {
      EditableNode = (
        <div className="table-cell table-cell__suspend">
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}>
            <App.RadioGroup direction="column" fontSize="12px">
              {
                record[dataIndex].toLowerCase() === "yes" ? (
                  <>
                    <App.Radio id="suspend-open" name={dataIndex} label={t("YES")} value={1} defaultChecked />
                    <App.Radio id="suspend-closed" name={dataIndex} label={t("NO")} value={0} />
                  </>
                ) : (
                  <>
                    <App.Radio id="suspend-open" name={dataIndex} label={t("YES")} value={1} />
                    <App.Radio id="suspend-closed" name={dataIndex} label={t("NO")} value={0} defaultChecked />
                  </>
                )
              }
            </App.RadioGroup>
          </Form.Item>
        </div>
      );
    } else if (dataIndex === "lock") {
      EditableNode = (
        <div className="table-cell table-cell__suspend">
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}>
            <App.RadioGroup direction="column" fontSize="12px">
              {
                record[dataIndex].toLowerCase() === "lock" ? (
                  <>
                    <App.Radio id="lock-open" name={dataIndex} label={t("LOCK")} value={1} defaultChecked />
                    <App.Radio id="lock-closed" name={dataIndex} label={t("UNLOCK")} value={0} />
                  </>
                ) : (
                  <>
                    <App.Radio id="lock-open" name={dataIndex} label={t("LOCK")} value={1} />
                    <App.Radio id="lock-closed" name={dataIndex} label={t("UNLOCK")} value={0} defaultChecked />
                  </>
                )
              }
            </App.RadioGroup>
          </Form.Item>
        </div>
      );
    } else if (dataIndex === "memo") {
      EditableNode = (
        <div className="table-cell table-cell__memo">
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}>
            <Input />
          </Form.Item>
        </div>
      );
    }

    return (
      <td {...restProps}>
        {editing ? EditableNode : children}
      </td>
    );
  };

  return (
    <div>
      <SLBreadcrumb items={[t("FILTER")]} style={{ marginTop: 15 }} />
      <Filter
        sData={[
          [
            {
              span: 9,
              key: "col-9",
              field: (
                <Form.Item label={t("SEARCH")}>
                  <FormInput ref={searchRef} />
                </Form.Item>
              )
            },
            {
              span: 7,
              key: "col-7",
              field: (
                <Form.Item label={t("STATUS")}>
                  <App.Select
                    defaultValue={status}
                    style={{ width: "120px" }}
                    onChange={onStatusChange}
                    list={[
                      {
                        label: t("ALL"),
                        value: "all"
                      },
                      {
                        label: t("OPEN"),
                        value: 1
                      },
                      {
                        label: t("CLOSED"),
                        value: 0
                      },
                    ]} />
                </Form.Item>
              )
            },
            {
              span: 4,
              key: "col-4",
              field: <Button onClick={handleSubmit}>{t("SUBMIT")}</Button>
            },
          ]
        ]} />
      <SLBreadcrumb items={[t("ACCOUNT_LIST"), ...breadcrumbList]} />
      <Card>
        <Form form={form} onFinish={onSaveRecord}>
          {
            accessDenied ? t("INVALID_DATA") : (
              <SLTable
                rowClassName="table-row"
                components={{ body: { cell: EditableStatusCell } }}
                columns={columns}
                data={dataSource}
                pagination={{ position: ["topLeft", "bottomLeft"] }}
                loading={loading}
                bordered />
            )
          }
        </Form>
        <App.MessageAlert type={message.type} description={message.description} />
      </Card>
    </div>
  );
};

export default AccountList;
