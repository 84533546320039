import React,{useState} from "react";
import { Row,Col,Table } from "antd";
import Breadcrumb from "../../components/Breadcrumb";
import Info from "../../components/Dashboard/info";
import Chart from "../../components/Dashboard/chartjs";
import {useAuth} from "../../hooks/auth";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./Dashboard.scss"
const Dashboard = () => {
  const {t} = useTranslation();
  const {info}= useAuth();
  const [turnoverFilterData,setTurnoverFilter] = useState({});
  const [startTime,setStartTime] = useState(moment().subtract(1,'week').startOf('week').format("X"));
  const [endTime,setEndTime] = useState(moment().endOf('week').format("X"));
  const information = {
    username: info.username,
    level   : info.level,
    apiParam:{action: "DASHBOARD_INFO",username:  info.username}
  }

  return(
    <div className="dashboard">
      <Row gutter={[16, 16]}>
        <Col span={12} className="information">
          <Breadcrumb items={[t("INFORMATION")]}></Breadcrumb>
          <Info data={information} ></Info>
        </Col>

        {/* <Col span={12} className="maintenance-status">
        <Breadcrumb items={[t("MAINTENANCE_STATUS")]}></Breadcrumb>
        Maintenance Status
        </Col> */}
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
        <Breadcrumb items={[t("KEY_GRAPH")]}></Breadcrumb>
        <Chart chart={{title: "Turnover",apiParam:{action: "DASHBOARD_TURNOVER",username:  info.username,startTime: startTime,endTime: endTime}}}></Chart>
        </Col>

        <Col span={12}>
        <Breadcrumb items={[t("KEY_GRAPH")]}></Breadcrumb>
        <Chart chart={{title: "Winloss",base: 0,apiParam:{action: "DASHBOARD_WINLOSS",username:  info.username,startTime: startTime,endTime: endTime}}}></Chart>
        </Col>
      </Row>
  </div>
  )
};

export default Dashboard;
