import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

import "./style.scss";

const SLTable = ({
  columns,
  data,
  id = "sl-table",
  bordered,
  className = "",
  pagination,
  scroll,
  components,
  rowClassName,
  loading,
  summary,
  footer
}) => {
  const { t } = useTranslation();

  return data && data.length > 0 ? (
    <Table
      id={id}
      columns={columns}
      dataSource={data}
      components={components}
      rowClassName={rowClassName}
      bordered={bordered}
      className={className}
      pagination={pagination}
      scroll={scroll}
      loading={loading}
      summary={summary}
      footer={footer} />
  ) : (<p>{t("NO_RECORD_FOUND")}</p>)
};

export default SLTable;
