import React, { useState } from "react";
import { PageHeader, Tabs, Card, Button, Space, Modal, Tag } from "antd";
import PaylineBoard from "../../components/PaylineBoard";
import constants from "../../helpers/constants";
import Helpers from "../../helpers/utils";
import State from "../../data";
import SLBreadcrumb from "../../components/Breadcrumb";
import "./Payline.scss";

const getInitialPaylineBoard = (paylines) => {
  const paylineBoard = paylines.reduce((total, payline) => {
    const board = total;

    board[payline.type] = payline.lines.map((line) => line.map((value, index) => ({ x: value, y: index })));

    return board;
  }, {});

  return paylineBoard;
};

const Payline = () => {
  const { TabPane } = Tabs;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paylineBoardKey, updatePaylineBoardKey] = useState(Helpers.generateRandomKey());
  const [paylineBoard, updatePaylineBoard] = useState(getInitialPaylineBoard(State.paylines));
  const [newPayline, updateNewPayline] = useState([]);
  const [activeTab, setActiveTab] = useState(constants.PaylineTab.LINE);
  const tabPanes = [];

  const tabs = [
    {
      type: constants.PaylineTab.LINE,
      title: "Line"
    },
    {
      type: constants.PaylineTab.SCATTER,
      title: "Scatter"
    }
  ];

  const handleAddPayline = () => {
    setIsModalVisible(true);
  };

  const handleResetBoard = () => {
    updatePaylineBoardKey(Helpers.generateRandomKey());
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    updatePaylineBoardKey(Helpers.generateRandomKey());
  };

  const handleEachDraw = (drawingPayline) => {
    if (drawingPayline.length > 1) {
      updateNewPayline([...drawingPayline]);
    }
  };

  const onFinishDrawingPayline = () => {
    paylineBoard[activeTab] = paylineBoard[activeTab] || [];
    paylineBoard[activeTab].push(newPayline);
    updatePaylineBoard(paylineBoard);
    handleCancel();
  };

  const handleSelectTab = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  for (let i = 0; i < tabs.length; i++) {
    const tab = tabs[i];
    const paylines = paylineBoard && paylineBoard[tab.type] ? paylineBoard[tab.type] : [];
    const paylineBoardNodes = [];

    for (let j = 0; j < paylines.length; j++) {
      const payline = paylines[j];

      paylineBoardNodes.push(
        <div key={Helpers.generateRandomKey()} className="payline-board__wrapper">
          <PaylineBoard
            dotSize={5}
            cellSize={40}
            key={Helpers.generateRandomKey()}
            numberOfColumns={State.settings.number_of_reels}
            numberOfRows={State.settings.number_of_rows}
            eachDraw={handleEachDraw}
            defaultPayline={payline}
            isScatterBoard={tab.type === constants.PaylineTab.SCATTER}
            {...State.settings.payline_board} />
        </div>
      );
    }

    const tabPane = (
      <TabPane tab={tab.title} key={tab.type}>
        <Space style={{ display: "flex", flexWrap: "wrap", padding: "2px" }}>
          {paylineBoardNodes && paylineBoardNodes.map((paylineBoardNode) => paylineBoardNode)}
        </Space>
      </TabPane>
    );

    tabPanes.push(tabPane);
  }

  return (
    <div>
      {/* <PageHeader
        className="site-page-header"
        title="Payline"
        subTitle="Add paylines of specific symbols on which a payout will be awarded"
        /> */}

      <section>
        <section className="button-group">
          <Button type="primary" onClick={handleAddPayline}>
            Add a payline
          </Button>
          <SLBreadcrumb items={["Settings", "Payline"]} style={{ marginTop: 15 }} />
          <div className="tag-group">
            <Tag key={activeTab}>
              <span>Number of Paylines</span> - <b>{paylineBoard[activeTab]?.length}</b>
            </Tag>
          </div>
        </section>
        <Card size="small">
          <Tabs defaultActiveKey={activeTab} onChange={handleSelectTab}>
            {tabPanes}
          </Tabs>
        </Card>
      </section>

      <Modal
        title="Add Payline"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="payline-modal"
        footer={[
          <Button key="reset" type="danger" style={{ float: "left" }} onClick={handleResetBoard}>Reset board</Button>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onFinishDrawingPayline}>
            Finish
          </Button>
        ]}>
        <div className="payline-modal__board">
          <PaylineBoard
            dotSize={10}
            cellSize={80}
            defaultPayline={[]}
            key={paylineBoardKey}
            numberOfColumns={State.settings.number_of_reels}
            numberOfRows={State.settings.number_of_rows}
            eachDraw={handleEachDraw}
            isScatterBoard={activeTab === constants.PaylineTab.SCATTER}
            {...State.settings.payline_board} />
        </div>
      </Modal>
    </div>
  );
};

export default Payline;
