const { NODE_ENV } = process.env;
let serverUrl;

if (NODE_ENV === "production") {
  serverUrl = "https://server.wowsoultion.com/";
} else {
  serverUrl = "https://server.wowsoultion.com/";
}

export default { serverUrl };
