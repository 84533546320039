import styled from "styled-components";

const RadioGroup = styled.div`
  display: flex;
  flex-direction: ${({ direction = "row" }) => direction};
  justify-content: center;
  gap: 5px;
  
  label {
    font-size: ${({ fontSize = "14px" }) => fontSize};
  }
`;

export default RadioGroup;