import React, { useState, useEffect } from "react";
import { Card, Form, Input, Switch, Button } from "antd";
import { useTranslation } from "react-i18next";
import MAProduct from "../../components/MAProduct";
import SLBreadcrumb from "../../components/Breadcrumb";
import HTTPService from "../../services/HTTPService";
import { useAuth } from "../../hooks/auth";
import constants from "../../helpers/constants";
import utils from "../../helpers/utils";
import App from "../../App.jsx";

import {
  CloseOutlined,
  CheckOutlined
} from "@ant-design/icons";

import "./AccountCreation.scss";

const API_URL = process.env.REACT_APP_API_SERVER;

const state = constants.state;

const ShowProducts = ({ title, list, isImageOnly }) => {
  return (
    <section className="product__section">
      <div className="product__header">
        <h4><b>{title}</b></h4>
      </div>
      <div className="product__list">
        {
          list.map(item => <MAProduct key={item.name} product={item} isImageOnly={isImageOnly} />)
        }
      </div>
    </section>
  )
};

const PrefixFormItem = ({ label, lastMemberId, prefixes, defaultValue, PrefixIcon, onChange }) => {
  return (
    <Form.Item
      label={label}
      name="prefix"
      className="form__item-prefix">
      {lastMemberId}
      <App.Select defaultValue={defaultValue[0]} style={{ width: "40px", marginLeft: "10px" }} list={prefixes} onChange={(e) => onChange("f", e.target.value)} />
      <App.Select defaultValue={defaultValue[1]} style={{ width: "40px", marginLeft: "10px" }} list={prefixes} onChange={(e) => onChange("s", e.target.value)} />
      {PrefixIcon}
    </Form.Item>
  );
};

const UsernameFormItem = ({ label, defaultValue }) => {
  return (
    <Form.Item
      label={label}
      rules={[{ required: true, message: 'Please input your username.' }]}>
      <Input name="username" value={defaultValue} disabled />
    </Form.Item>
  );
};

const NameFormItem = ({ label }) => {
  return (
    <Form.Item
      label={label}
      name="name"
      rules={[{
        required: true,
        message: 'Please input your name.'
      },
      {
        min: 3,
        message: "Value should be at least 3 character",
      },
      {
        max: 15,
        message: "Value should be less than 15 character",
      }
    ]}>
      <Input />
    </Form.Item>
  );
};

const PasswordFormItem = ({ label }) => {
  return (
    <Form.Item
      label={label}
      name="password"
      rules={[{
        required: true,
        message: "Please input your password."
      },
      {
        min: 6,
        message: "Value should be at least 6 character",
      },
      {
        max: 30,
        message: "Value should be less than 30 character",
      }
    ]}>
      <Input.Password />
    </Form.Item>
  );
};

const StatusFormItem = ({ label, children }) => {
  return (
    <Form.Item
      label={label}
      name="status">
      {children}
    </Form.Item>
  );
};

const LevelFormItem = ({ label, children }) => {
  return (
    <Form.Item
      label={label}
      name="level">
      {children}
    </Form.Item>
  );
};

const AgentPrefixFormItem = ({ label, lastMemberId, prefixes, defaultValue, PrefixIcon, onChange }) => {
  return (
    <Form.Item
      label={label}
      name="agent_prefix"
      className="form__item-prefix">
      <App.Select defaultValue={defaultValue[0]} style={{ width: "40px" }} list={prefixes} onChange={(e) => onChange("f", e.target.value)} />
      <App.Select defaultValue={defaultValue[1]} style={{ width: "40px", marginLeft: "10px" }} list={prefixes} onChange={(e) => onChange("s", e.target.value)} />
      <App.Select defaultValue={defaultValue[2]} style={{ width: "40px", marginLeft: "10px" }} list={prefixes} onChange={(e) => onChange("t", e.target.value)} />
      {PrefixIcon}
    </Form.Item>
  );
};

const CountryFormItem = ({ label, countries, defaultValue, onChange }) => {
  return (
    <Form.Item
      label={label}
      name="country"
      className="form__item-country">
      <App.Select defaultValue={defaultValue} style={{ width: "120px" }} list={countries} onChange={onChange} />
    </Form.Item>
  );
};

const fetchData = async (params, header, cb) => {
  const response = await HTTPService.get(API_URL, params, header);
  return cb(response);
};

const postData = async (params, header, cb) => {
  const response = await HTTPService.post(API_URL, params, header);
  return cb(response);
};

const AccountCreation = () => {
  const { t } = useTranslation();
  const { info: user } = useAuth();
  const [form] = Form.useForm();
  const [products, updateProducts] = useState([]);
  const [lastMemberId, updateLastMemberId] = useState(user.username);
  const [username, updateUsername] = useState('');
  const [prefix, updatePrefix] = useState([0, 0]);
  const [status, updateStatus] = useState(1);
  const [level, updateLevel] = useState(user.level < 3 ? user.level + 1 : user.level);
  const [prefixIconValidation, updatePrefixIconValidation] = useState(<CloseOutlined />);
  const [message, updateMessage] = useState({ type: state.NORMAL, description: "" });
  const [agentPrefix, updateAgentPrefix] = useState([0, 0, 0]);
  const [agentPrefixIconValidation, updateAgentPrefixIconValidation] = useState({ value: false, icon: <CloseOutlined /> });
  const [selectedCountry, updateSelectedCountry] = useState("KH");
  const [loading, setLoading] = useState(false);

  const countries = utils.getCountries()
    .filter((country) => ["kr", "kh", "us", "th", "id", "ph", "vn", "cn", "la", "my", "in", "jp", "sg"].includes(country.code.toLowerCase().trim()))
    .sort((a, b) => {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    })
    .map((country) => {
      return {
        label: `${country.emoji} ${country.name}`,
        value: country.code
      };
    });

  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  const prefixes = Array.from(Array(10).keys()).concat(alphabet).reduce((total, value) => {
    const item = {};

    item["label"] = value;
    item["value"] = value;

    total.push(item);

    return total;
  }, []);

  useEffect(async () => {
    fetchData({ action: "GET_PRODUCT" }, { "api-key": user.api_key }, (res) => {
      if (res && res.length) updateProducts(res);
    });
  }, []);

  useEffect(() => {
    updateUsername(() => {
      return lastMemberId + prefix.join("");
    });
  }, [prefix]);

  useEffect(() => {
    if (username && username.length) {
      fetchData({ action: "CHECK_IF_USERNAME_EXISTS", username }, { "api-key": user.api_key }, (res) => {
        if (res) {
          const exists = res.exists;
  
          if (!exists) {
            updatePrefixIconValidation({ value: true, icon: <CheckOutlined /> });
          } else {
            updatePrefixIconValidation({ value: false, icon: <CloseOutlined /> });
          }
        }
      });
    }
  }, [username]);

  useEffect(() => {
    fetchData({ action: "CHECK_IF_AGENT_PREFIX_EXISTS", ag_prefix: agentPrefix.toString() }, { "api-key": user.api_key }, (res) => {
      if (res) {
        const exists = res.exists;

        if (!exists) {
          updateAgentPrefixIconValidation({ value: true, icon: <CheckOutlined /> });
        } else {
          updateAgentPrefixIconValidation({ value: false, icon: <CloseOutlined /> });
        }
      }
    });
  }, [agentPrefix]);

  useEffect(() => {
    if (message.type !== state.NORMAL) {
      let timer = setTimeout(() => {
        clearTimeout(timer);
        updateMessage({ type: state.NORMAL, description: "" });
      }, 5000);
    }
  }, [message]);

  const handlePrefixChange = (type, value) => {
    updatePrefix((old) => {
      return type === "f" ? [value, old[1]] : [old[0], value];
    });
  };

  const handleAgentPrefixChange = (type, value) => {
    updateAgentPrefix((old) => {
      let updatedValue = [value, old[1], old[2]];

      if (type === "s") {
        updatedValue = [old[0], value, old[2]];
      } else if (type === "t") {
        updatedValue = [old[0], old[1], value];
      }

      return updatedValue;
    });
  };

  const onFinish = (values) => {
    values.username = username;
    values.status = status;
    values.level = level;
    values.prefix = prefix.toString();
    values.agent_prefix = agentPrefix.toString();
    values.country = selectedCountry;

    let prefixValue = user.level <= 1 ? prefixIconValidation.value : agentPrefixIconValidation.value;
    
    if (!prefixValue) {
      return updateMessage({
        type: state.FAILED,
        description: t("INVALID_PREFIX")
      });
    }

    if (values.name && values.password) {
      setLoading(true);
  
      postData({ action: "CREATE_NEW_MEMBER", ...values }, { "api-key": user.api_key }, (res) => {
        setLoading(false);
  
        if (res && res.status) {
          form.resetFields();
  
          updateUsername(lastMemberId + "00");
          updateAgentPrefix([...agentPrefix]);
  
          updateMessage({
            type: state.SUCCESS,
            description: res.message
          });
        } else {
          updateMessage({
            type: state.FAILED,
            description: res.message
          });
        }
      });
    }
  };

  const handleCountryChange = (e) => {
    const value = e.target.value;
    updateSelectedCountry(value);
  };

  return (
    <div>
      <SLBreadcrumb items={["New Account"]} style={{ marginTop: 15 }} />
      <Card>
        <Form form={form} name="basic" className="page-form" onFinish={onFinish}>
          <section>
            <div className="page-form__input-group">
              <Input.Group>
                <PrefixFormItem
                  label={t("AG_CODE")}
                  lastMemberId={lastMemberId}
                  prefixes={prefixes}
                  defaultValue={prefix}
                  PrefixIcon={prefixIconValidation.icon}
                  onChange={handlePrefixChange} />
                {
                  user.level > 1 && (
                    <AgentPrefixFormItem
                      label={t("AGENT_PREFIX")}
                      prefixes={prefixes}
                      defaultValue={agentPrefix}
                      PrefixIcon={agentPrefixIconValidation.icon}
                      onChange={handleAgentPrefixChange} />
                  )
                }
                <UsernameFormItem label={t("AG_CODE")} defaultValue={username} />
              </Input.Group>
              <Input.Group>
                <NameFormItem label={t("AG_ID")} />
                <PasswordFormItem label={t("PASSWORD")} />
              </Input.Group>
              <Input.Group>
                <StatusFormItem label={t("STATUS")}>
                  {
                    <App.Select
                      defaultValue={status}
                      style={{ width: "120px" }}
                      list={[
                        {
                          label: t("INACTIVE"),
                          value: 0
                        },
                        {
                          label: t("ACTIVE"),
                          value: 1
                        },
                      ]}
                      onChange={(e) => updateStatus(+e.target.value)} />
                  }
                </StatusFormItem>
                <LevelFormItem label={t("LEVEL")}>
                  {
                    <App.Select
                    disabled
                    defaultValue={level}
                    style={{ width: "120px" }}
                    list={[
                      {
                        label: t("SUPER_MASTER_AGENT"),
                        value: 1
                      },
                      {
                        label: t("MASTER_AGENT"),
                        value: 2
                      },
                      {
                        label: t("AGENT"),
                        value: 3
                      },
                    ]}
                    onChange={(e) => updateLevel(+e.target.value)} />
                  }
                </LevelFormItem>
              </Input.Group>
              <Input.Group>
                <CountryFormItem 
                  label={t("COUNTRY")} 
                  countries={countries}
                  defaultValue={selectedCountry}
                  onChange={handleCountryChange}>
                </CountryFormItem>
              </Input.Group>
            </div>
          </section>
          {/* <ShowProducts title={
            <div className="product__header">
              <h4><b>{t("CASINO_PRODUCT")}</b></h4>
            </div>
          } list={products.filter(product => product.type === 1)} /> */}
          {/* <ShowProducts title={
            <div className="product__header">
              <h4><b>{t("SLOT_PRODUCT")}</b></h4>
              <Switch defaultChecked />
            </div>
          } list={products.filter(product => product.type === 2 && product.status)} isImageOnly={true} /> */}
          <Form.Item className="page-footer">
            {
              loading ? (
                <Button loading disabled>{t("LOADING")}</Button>
              ) : (
                <Button htmlType="submit">{t("CREATE")}</Button>
              )
            }
            <App.MessageAlert type={message.type} description={message.description} />
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AccountCreation;
