import './Summary.scss';
import React, { useEffect, useState, useReducer } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, DatePicker, Button, Table, Select } from "antd";
import moment from "moment";

import Filter from "../../components/Filters/Filter";
import SLTable from "../../components/SLTable/SLTable";
import SLBreadcrumb from "../../components/Breadcrumb";
import HTTPService from "../../services/HTTPService";
import { SearchOutlined } from "@ant-design/icons";
import { useAuth } from "../../hooks/auth";
import utils from "../../helpers/utils";

const API_URL = process.env.REACT_APP_API_SERVER;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Summary = () => {
  let query = useQuery();

  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { numberFormatAsCurrency } = utils;

  const [startTime, setStartTime] = useState(moment().startOf('day').format("X"));
  const [endTime, setEndTime] = useState(moment().endOf('day').format("X"));
  const [gameList, setGameList] = useState([{ game_id: 'vsggd79' }, { game_id: 'vsggd90' }, { game_id: 'vsbook90' }, { game_id: "vsbook79" }, { game_id: "vspuben79" }, { game_id: "vspuben90" }]);
  const [selectedGame, setSelectedGame] = useState(['All']);
  const [tableHeader, setTableHeader] = useState([]);
  const [breadcrumb, dispatch] = useReducer(reducer, ['Report', 'Summary', <NavLink onClick={() => dispatch({ act: 'remove' })} to={"/report/summary"}>{query.get('tie')}</NavLink>]);
  const [footer, setFooter] = useState([0, 0, 0, 0]);
  const [data, setData] = useState([]);
  const [tie, setTie] = useState('');
  const [b, setB] = useState([]);
  const location = useLocation();
  const { info } = useAuth();

  function reducer(state, newState) {
    switch (newState.act) {
      case 'add':
        return ['Report', 'Summary', <NavLink onClick={() => dispatch({ act: 'remove' })} to={"/report/summary"}>{newState.tie}</NavLink>];
      default:
        return state.slice(0, state.length - 1);
    }
  }

  const onSearch = async () => {
    const filterData = {
      action: "GET_SUMMARY",
      start_date: startTime,
      level: info.level,
      end_date: endTime,
      tie: query.get('tie'),
      agn_id: info.username,
      game_id: selectedGame
    }

    fetchData(filterData);
  }

  const fetchData = async (filterData, cb) => {
    filterData = filterData || {
      action: "GET_SUMMARY",
      level: info.level,
      start_date: startTime,
      end_date: endTime,
      tie: query.get('tie'),
      agn_id: info.username,
      game_id: selectedGame
    }

    const resp = await HTTPService.get(API_URL, filterData, { "api-key": info.api_key });
    const datas = resp.data;
    const t = {};

    let rows = [];

    if (cb) {
      return cb(null, datas);
    }

    let totals = {}
    let waggerTotalCol = 0;
    let turnoverTotalCol = 0;
    let winlossTotalCol = 0;
    let winTotalCol = 0;
    let RTPTotalCol = 0;

    for (let i = 0; i < datas.length; i++) {
      const col = {
        ma: info.username,
        ag: "00200"
      }

      if (totals[datas[i].username]) {
        totals[datas[i].username].wager += datas[i].wager;
        totals[datas[i].username].turnover += datas[i].turnover;
        totals[datas[i].username].winloss += datas[i].winlose;
      } else {
        totals[datas[i].username] = {};
        totals[datas[i].username].wager = datas[i].wager;
        totals[datas[i].username].turnover = datas[i].turnover;
        totals[datas[i].username].winloss = datas[i].winlose;
      }

      col.ma = query.get('tie') ? datas[i].username : datas[i].username;
      col.ag = resp.child;
      col["key"] = 'key-' + i;
      col['wager-' + datas[i].game_id] = numberFormatAsCurrency(datas[i].wager);
      col['turnover-' + datas[i].game_id] = numberFormatAsCurrency(datas[i].turnover);
      col['winloss-' + datas[i].game_id] = numberFormatAsCurrency(datas[i].winlose);
      col['wager-total'] = numberFormatAsCurrency(totals[datas[i].username].wager);
      col['turnover-total'] = numberFormatAsCurrency(totals[datas[i].username].turnover);
      col['winloss-total'] = numberFormatAsCurrency(totals[datas[i].username].winloss);
      col['win'] = numberFormatAsCurrency(datas[i].total_win);
      col['rtp'] = ((datas[i].total_win / totals[datas[i].username].turnover) * 100).toFixed(2) + "%";

      t[datas[i].username] = { ...t[datas[i].username], ...col };

      waggerTotalCol += datas[i].wager;
      turnoverTotalCol += datas[i].turnover;
      winlossTotalCol += datas[i].winlose;
      winTotalCol += datas[i].total_win;
    }
    
    RTPTotalCol = ((winTotalCol / turnoverTotalCol) * 100).toFixed(2) + "%";
    rows = Object.values(t);
    
    setFooter([waggerTotalCol, turnoverTotalCol, winlossTotalCol, RTPTotalCol]);
    setData(rows);

    if (!datas.length) setData([{
      "wager-total": numberFormatAsCurrency(0),
      "turnover-total": numberFormatAsCurrency(0),
      "winloss-total": numberFormatAsCurrency(0),
      'key': "total",
      "ma": info.username,
      "win": 0
    }]);
  }

  function onDateChange(date) {
    setStartTime(date[0].startOf("day").format("X"));
    setEndTime(date[1].endOf("day").format("X"));
  }

  function generateCols() {
    c.push({
      title: t("TOTAL"),
      key: 'total',
      render: (text) => <a>{text}</a>,
      children: [
        {
          title: t("WAGER"),
          width: 150,
          dataIndex: "wager-total",
          key: "wager-total"
        },
        {
          title: t("TOTAL_BET"),
          width: 150,
          dataIndex: "turnover-total",
          key: "turnover-total"
        },
        {
          title: t("WIN"),
          width: 150,
          dataIndex: "win",
          key: "winloss-total",
          // render: (t) => {
          //   t ? t =  "" + t : '0';
          //   if(t.split("-").length > 1){
          //     return <span style={{color:'red'}}>{t}</span>
          //   } else {
          //     return t;
          //   }
          // }
        },
        {
          title: t("RTP"),
          width: 150,
          dataIndex: "rtp",
          key: "rtp"
        },
      ]
    });

    setTableHeader(c);
  }

  const c = [
    {
      title: query.get('tie') ? t("USERNAME") : t("AG_CODE"),
      width: 150,
      dataIndex: "ma",
      key: "username",
      render: (text) => {
        let element;

        if (query.get('tie')) {
          element = <span>{text}</span>;
        } else {
          element = <NavLink onClick={() => dispatch({ act: 'add', tie: text })} to={"summary?tie=" + text}>{text}</NavLink>;
        }

        return element;
      }
    }
  ];

  const Children = (
    <>
      {gameList.map((game) => {
        // let shortName = JSON.stringify(game).slice(2,game.length + 1);
        return <Select.Option key={game}>{game}<div></div></Select.Option>
      })}
    </>
  );

  const handleChange = (value) => {
    let allIndex = value.indexOf('All');

    if (allIndex != -1) {
      value = value.filter((item, i) => allIndex != i);
    }

    setSelectedGame(value);
  };

  const totalSummary = ((items) => {
    let totalWagger = 0;
    let totalTurnover = 0;
    let totalWinloss = 0;
    let totalRTP = 0;
    let totalWin = 0;

    for (let i = 0; i < items.length; i++) {
      totalWagger += +((items[i]['wager-total'] + "").replace(/,/gi, ''));
      totalTurnover += +((items[i]['turnover-total'] + "").replace(/,/gi, ""));
      totalWinloss += +((items[i]['winloss-total'] + "").replace(/,/gi, ''));
      totalWin += +((items[i]["win"] + "").replace(/,/gi, ''));
    }

    totalRTP = ((totalWin / totalTurnover) * 100);

    if (totalRTP) {
      totalRTP = totalRTP.toFixed(2) + "%";
    } else {
      totalRTP = '';
    }

    return (
      <Table.Summary className="footer">
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>{t("TOTAL")}</Table.Summary.Cell>
          <Table.Summary.Cell key="total-wagger">{numberFormatAsCurrency(totalWagger)}</Table.Summary.Cell>
          <Table.Summary.Cell key="total-turnover">{numberFormatAsCurrency(totalTurnover)}</Table.Summary.Cell>
          <Table.Summary.Cell key="total_winloss"><span style={{ color: totalWin >= 0 ? "" : "red" }}>{numberFormatAsCurrency(totalWin)}</span></Table.Summary.Cell>
          <Table.Summary.Cell key="total_rtp">{totalRTP}</Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  });

  const filterField = [[{
    span: 9,
    key: "col-1",
    field: <RangePicker
      key="date-picker"
      size="small"
      defaultValue={[moment(startTime * 1000), moment(endTime * 1000)]}
      onChange={onDateChange} />
  },
  {
    span: 9,
    key: "game_id",
    field: <Select
      mode="multiple"
      allowClear
      style={{ width: '100%' }}
      placeholder="Please Select Game"
      defaultValue={selectedGame}
      value={selectedGame}
      onChange={handleChange}
      onSelect={(e) => {
        if (e.toLowerCase() == "all") {
          setSelectedGame(['All']);
        }
      }}
      onDeselect={(e) => {
        if (e.toLowerCase() == "all") {
          setSelectedGame([]);
        }
      }}
      size="small"
    >
      {Children}
    </Select>
  },
  {
    span: 4,
    key: "col-3",
    field:
      <Button
        type="primary"
        size="small"
        onClick={onSearch}
        icon={<SearchOutlined />}>
        {t("SEARCH")}
      </Button>
  }
  ], []];

  useEffect(() => {
    let breadcrumbParam = {
      action: "GET_BREADCRUMB_BY_USERNAME",
      username: query.get('tie') || 'null'
    };

    fetchData(breadcrumbParam, (err, data) => {
      if (err) throw err;

      let br = [t("REPORT"), <NavLink to={"summary"}>{t("SUMMARY")}</NavLink>];

      data.shift();
      data = data.slice(data.indexOf(info.username));
      data.push(query.get('tie'));
      data.map((item) => {
        let url = "/report/summary?tie=" + item;
        br.push(<NavLink to={url}>{item}</NavLink>);
      });

      setB(br);
    });

    setTie(query.get('tie'));
    generateCols();

    fetchData({ "action": "GET_GAME_LIST" }, (err, games) => {
      let gameList = ['All'];

      for (let i = 0; i < games.length; i++) {
        gameList.push(games[i].game_id);
      }

      setGameList(gameList);
    });
  }, [location.search]);
  
  useEffect(() => {
    onSearch();
  }, [tie]);

  return (
    <div id="summary">
      {/* <PageHeader title="Summary" /> */}
      <SLBreadcrumb items={[t("FILTER")]} style={{ marginTop: 15 }} />
      <Filter sData={filterField} />
      <SLBreadcrumb items={b} />
      <section>
        <Card id="summary-report">
          <SLTable
            bordered
            columns={tableHeader}
            data={data}
            pagination={false}
            scroll={{ x: `${100}vw` }}
            summary={totalSummary} />
        </Card>
      </section>
    </div>
  );
};

export default Summary;
