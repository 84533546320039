import React from "react";
import { Breadcrumb } from "antd";
import "./style.scss";

const SLBreadcrumb = ({ items = ["list"], className = "custom-breadcrumb", style }) => (
  <Breadcrumb className={className} style={style}>
    <div className="card-header-bgcl" />
    <div className="bca">
      {items.map((item, index) => (
        <Breadcrumb.Item key={'item-' + index}>{item}</Breadcrumb.Item>
      ))}
    </div>

    <div className="card-header-headerc" />
  </Breadcrumb>
);

export default SLBreadcrumb;
