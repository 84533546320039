// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaylineBoard_canvas__1YD1P {\n  border: 1px solid #014d5d21; }\n\n.PaylineBoard_container__3ifBR {\n  width: 30em;\n  height: 30em; }\n  .PaylineBoard_container__col__2AN7i {\n    background-color: #DFF1F5; }\n", "",{"version":3,"sources":["webpack://src/components/PaylineBoard/PaylineBoard.module.scss"],"names":[],"mappings":"AAEA;EACE,2BAA2B,EAAA;;AAG7B;EACE,WAAW;EACX,YAAY,EAAA;EAEZ;IACE,yBAXgB,EAAA","sourcesContent":["$cell-color: #DFF1F5;\n\n.canvas {\n  border: 1px solid #014d5d21;\n}\n\n.container {\n  width: 30em;\n  height: 30em;\n\n  &__col {\n    background-color: $cell-color;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas": "PaylineBoard_canvas__1YD1P",
	"container": "PaylineBoard_container__3ifBR",
	"container__col": "PaylineBoard_container__col__2AN7i"
};
export default ___CSS_LOADER_EXPORT___;
