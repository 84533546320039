import React, { useState } from "react";
import { Card, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import SLBreadcrumb from "../../components/Breadcrumb";
import { useAuth } from "../../hooks/auth";
import utils from "../../helpers/utils";
import App from "../../App.jsx";

import { CloseOutlined } from "@ant-design/icons";

import "./Profile.scss";

const PrefixFormItem = ({ label, lastMemberId, prefixes, defaultValue, PrefixIcon, onChange }) => {
  return (
    <Form.Item
      label={label}
      name="prefix"
      className="form__item-prefix">
      {lastMemberId}
      <App.Select defaultValue={defaultValue[0]} style={{ width: "40px", marginLeft: "10px" }} list={prefixes} onChange={(e) => onChange("f", e.target.value)} disabled />
      <App.Select defaultValue={defaultValue[1]} style={{ width: "40px", marginLeft: "10px" }} list={prefixes} onChange={(e) => onChange("s", e.target.value)} disabled />
    </Form.Item>
  );
};

const UsernameFormItem = ({ label, defaultValue }) => {
  return (
    <Form.Item label={label}>
      <Input name="username" value={defaultValue} readOnly />
    </Form.Item>
  );
};

const NameFormItem = ({ label, defaultValue }) => {
  return (
    <Form.Item
      label={label}>
      <Input name="username" value={defaultValue} readOnly />
    </Form.Item>
  );
};

const StatusFormItem = ({ label, children }) => {
  return (
    <Form.Item
      label={label}
      name="status">
      {children}
    </Form.Item>
  );
};

const LevelFormItem = ({ label, children }) => {
  return (
    <Form.Item
      label={label}
      name="level">
      {children}
    </Form.Item>
  );
};

const AgentPrefixFormItem = ({ label, lastMemberId, prefixes, defaultValue, PrefixIcon, onChange }) => {
  return (
    <Form.Item
      label={label}
      name="agent_prefix"
      className="form__item-prefix">
      <App.Select defaultValue={defaultValue[0]} style={{ width: "40px" }} list={prefixes} onChange={(e) => onChange("f", e.target.value)} disabled />
      <App.Select defaultValue={defaultValue[1]} style={{ width: "40px", marginLeft: "10px" }} list={prefixes} onChange={(e) => onChange("s", e.target.value)} disabled />
      <App.Select defaultValue={defaultValue[2]} style={{ width: "40px", marginLeft: "10px" }} list={prefixes} onChange={(e) => onChange("t", e.target.value)} disabled />
    </Form.Item>
  );
};

const CountryFormItem = ({ label, countries, defaultValue, onChange }) => {
  return (
    <Form.Item
      label={label}
      name="country"
      className="form__item-country">
      <App.Select defaultValue={defaultValue} style={{ width: "120px" }} list={countries} onChange={onChange} disabled />
    </Form.Item>
  );
};

const IDFormItem = ({ label, defaultValue }) => {
  return (
    <Form.Item label={label}>
      <span>{defaultValue}</span>
    </Form.Item>
  );
};

const APIKeyFormItem = ({ label, defaultValue }) => {
  return (
    <Form.Item label={label} style={{ width: "410px" }}>
      <Input.Password value={defaultValue} />
    </Form.Item>
  );
};

const AccountCreation = () => {
  const { t } = useTranslation();
  const { info: user } = useAuth();
  const [form] = Form.useForm();
  const [lastMemberId] = useState(user.username.substring(0, user.level <= 1 ? 1 : user.level + 1));
  const [username] = useState(user.username);
  const [prefix] = useState(user.prefix ? user.prefix.split(",") : [0,0]);
  const [status, updateStatus] = useState(1);
  const [level, updateLevel] = useState(user.level < 3 ? user.level + 1 : user.level);
  const [prefixIconValidation] = useState(<CloseOutlined />);
  const [agentPrefix] = useState(user.ag_prefix ? user.ag_prefix.split(",") : [0,0,0]);
  const [agentPrefixIconValidation] = useState({ value: false, icon: <CloseOutlined /> });
  const [selectedCountry] = useState(user.country.toUpperCase());
  const [name] = useState(user.name);

  const countries = utils.getCountries()
    .filter((country) => ["kr", "kh", "us", "th", "id", "ph", "vn", "cn", "la", "my", "in", "jp", "sg"].includes(country.code.toLowerCase().trim()))
    .sort((a, b) => {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    })
    .map((country) => {
      return {
        label: `${country.emoji} ${country.name}`,
        value: country.code
      };
    });

  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  const prefixes = Array.from(Array(10).keys()).concat(alphabet).reduce((total, value) => {
    const item = {};

    item["label"] = value;
    item["value"] = value;

    total.push(item);

    return total;
  }, []);

  return (
    <div>
      <SLBreadcrumb items={[t("PROFILE")]} style={{ marginTop: 15 }} />
      <Card>
        <Form form={form} name="basic" className="page-form">
          <section>
            <div className="page-form__input-group">
              <Input.Group>
                <IDFormItem
                  label={t("ID")}
                  defaultValue={user.id} />
                <PrefixFormItem
                  label={t("AG_CODE")}
                  lastMemberId={lastMemberId}
                  prefixes={prefixes}
                  defaultValue={prefix}
                  PrefixIcon={prefixIconValidation.icon} />
                {
                  user.level > 2 && (
                    <AgentPrefixFormItem
                      label={t("AGENT_PREFIX")}
                      prefixes={prefixes}
                      defaultValue={agentPrefix}
                      PrefixIcon={agentPrefixIconValidation.icon} />
                  )
                }
                <UsernameFormItem label={t("AG_CODE")} defaultValue={username} />
              </Input.Group>
              <Input.Group>
                <NameFormItem label={t("AG_ID")} defaultValue={name} />
                <LevelFormItem label={t("LEVEL")}>
                  {
                    <App.Select
                    disabled
                    defaultValue={level}
                    style={{ width: "120px" }}
                    list={[
                      {
                        label: t("SUPER_MASTER_AGENT"),
                        value: 1
                      },
                      {
                        label: t("MASTER_AGENT"),
                        value: 2
                      },
                      {
                        label: t("AGENT"),
                        value: 3
                      },
                    ]}
                    onChange={(e) => updateLevel(+e.target.value)} />
                  }
                </LevelFormItem>
              </Input.Group>
              <Input.Group>
                <StatusFormItem label={t("STATUS")}>
                  {
                    <App.Select
                      defaultValue={status}
                      style={{ width: "120px" }}
                      list={[
                        {
                          label: t("INACTIVE"),
                          value: 0
                        },
                        {
                          label: t("ACTIVE"),
                          value: 1
                        },
                      ]}
                      onChange={(e) => updateStatus(+e.target.value)}
                      disabled />
                  }
                </StatusFormItem>
                <CountryFormItem 
                  label={t("COUNTRY")} 
                  countries={countries}
                  defaultValue={selectedCountry} >
                </CountryFormItem>
              </Input.Group>
            </div>
          </section>
        </Form>
      </Card>
    </div>
  );
};

export default AccountCreation;
