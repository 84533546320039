import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Styles from "./PaylineBoard.module.scss";

const PaylineBoard = (props) => {
  const {
    cellSize,
    dotSize,
    lineWidth,
    numberOfRows,
    numberOfColumns,
    boardColor,
    lineColor,
    dotColor,
    isScatterBoard,
    defaultPayline,
    eachDraw
  } = props;

  const canvasRef = useRef(null);
  const width = numberOfColumns * cellSize;
  const height = numberOfRows * cellSize;

  let dots = defaultPayline;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const getCanvasMousePosition = (event) => {
      const rect = canvas.getBoundingClientRect();

      return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top
      };
    };

    const drawDot = (xCoordinate, yCoordinate) => {
      const halfCellSize = 0.5 * cellSize;
      const centerX = xCoordinate + halfCellSize;
      const centerY = yCoordinate + halfCellSize;
      const radius = dotSize;
      const startAngle = 0 * Math.PI;
      const endAngle = 2 * Math.PI;

      context.lineWidth = 10;
      context.fillStyle = dotColor;

      context.beginPath();
      context.arc(centerX, centerY, radius, startAngle, endAngle);
      context.closePath();
      context.fill();
    };

    const removeDot = (position) => {
      dots = dots.filter((dot) => dot.col !== position.axis.y);
    };

    const updateBoard = () => {
      context.fillStyle = boardColor;
      context.fillRect(0, 0, context.canvas.width, context.canvas.height);
      context.beginPath();

      context.lineCap = "round";
      context.lineWidth = lineWidth;
      context.strokeStyle = lineColor;

      /**
       * Horizontal lines
       */

      for (let x = 0; x < numberOfRows - 1; x++) {
        const rowHeight = cellSize;
        const rowLineYPos = rowHeight * (x + 1);

        context.moveTo(0, rowLineYPos);
        context.lineTo(width, rowLineYPos);
      }

      /**
       * Vertical lines
       */

      for (let y = 0; y < numberOfColumns - 1; y++) {
        const columnWidth = cellSize;
        const columnLineXPos = columnWidth * (y + 1);

        context.moveTo(columnLineXPos, 0);
        context.lineTo(columnLineXPos, height);
      }

      context.stroke();

      dots.forEach((dot) => {
        const xCoordinate = dot.y * cellSize;
        const yCoordinate = dot.x * cellSize;

        drawDot(xCoordinate, yCoordinate);
      });
    };

    const validateDrawingDot = (xAxis, yAxis) => {
      let validated = false;

      if (!dots.length && yAxis === 0) return true;

      if (!isScatterBoard) {
        /**
         * Validate whether dot is on the same column which is not allowed
         */
        validated = !dots.some((dot) => dot.y === yAxis);

        /**
         * Validate next dot position based on row and column
         */
        validated = validated && dots.some((dot) => Math.abs(xAxis - dot.x) <= 1 && dot.y === yAxis - 1);
      } else {
        /**
         * Scatter dot/symbol can be anywhere on board
         */
        validated = true;
      }

      return validated;
    };

    const addingDotOnBoard = (mouse) => {
      for (let x = 0; x < numberOfRows; x++) {
        for (let y = 0; y < numberOfColumns; y++) {
          const xCoordinate = y * cellSize;
          const yCoordinate = x * cellSize;

          if (
            mouse.x >= xCoordinate &&
            mouse.x <= xCoordinate + cellSize &&
            mouse.y >= yCoordinate &&
            mouse.y <= yCoordinate + cellSize
          ) {
            const drawn = dots.findIndex((dot) => dot.x === x && dot.y === y);

            if (drawn === -1) {
              const validated = validateDrawingDot(x, y);

              if (validated) {
                dots.push({ x, y });

                drawDot(xCoordinate, yCoordinate);
                eachDraw(dots);
              }
            }
          }
        }
      }
    };

    const onMouseUpOnCanvas = (event) => {
      const canvasMousePosition = getCanvasMousePosition(event);
      addingDotOnBoard(canvasMousePosition);
    };

    canvas.addEventListener("mouseup", onMouseUpOnCanvas);

    updateBoard();

    return () => {
      canvas.removeEventListener("mouseup", onMouseUpOnCanvas);
    };
  }, []);

  return <canvas className={Styles.canvas} ref={canvasRef} width={width} height={height} />;
};

PaylineBoard.propTypes = {
  boardColor: PropTypes.string,
  lineColor: PropTypes.string,
  dotColor: PropTypes.string,
  lineWidth: PropTypes.number,
  isScatterBoard: PropTypes.bool,
  defaultPayline: PropTypes.arrayOf(PropTypes.object),
  eachDraw: PropTypes.func.isRequired,
  dotSize: PropTypes.number.isRequired,
  cellSize: PropTypes.number.isRequired,
  numberOfRows: PropTypes.number.isRequired,
  numberOfColumns: PropTypes.number.isRequired
};

PaylineBoard.defaultProps = {
  lineWidth: 3,
  defaultPayline: [],
  isScatterBoard: false,
  boardColor: "#fff",
  lineColor: "#000",
  dotColor: "#000"
};

export default PaylineBoard;
