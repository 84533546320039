import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import utils from "../../helpers/utils";
import App from "../../App";
import State from "../../contexts/State";

import "./SelectLanguage.scss";

const flags = {
  "en": "us",
  "ko": "kr",
  // "kh": "kh"
};

const SelectLanguage = ({ defaultValue, isOpen, setOpen }) => {
  const { t, i18n } = useTranslation();
  const [flagUrl, updateFlagUrl] = useState(utils.getFlagUrl(flags[defaultValue]));
  const { setCurrentLanguage } = useContext(State);

  const languages = [{
      label: t('EN'),
      value: 'en'
    },
    {
      label: t('KO'),
      value: 'ko'
    },
    // {
    //   label: t('KH'),
    //   value: 'kh'
    // }
  ];

  const onChangeLanguage = (value) => {
    setCurrentLanguage(value);
    i18n.changeLanguage(value);
    updateFlagUrl(utils.getFlagUrl(flags[value]));
    window.sessionStorage.setItem("current_language", value);
  };

  return (
    <App.Dropdown
      caption={t("LANGUAGE")}
      displayItem={
        <img src={flagUrl} alt={defaultValue} />
      }
      list={languages}
      onClickItem={onChangeLanguage}
      isOpen={isOpen}
      setOpen={setOpen} />
  );
};

export default SelectLanguage;
