import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu } from "antd";

import constants from "../../helpers/constants";

import {
  RedditOutlined,
  BarChartOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  AlignLeftOutlined,
  FileDoneOutlined,
  MoneyCollectOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  ApiOutlined
} from "@ant-design/icons";

import "./style.scss";

const menuItem = {
  acctCreate: {}
};

const { admin, sma, ma, ag, mem } = constants.userTypes;

menuItem.acctCreate[admin] = {
  key: "new-ma",
  name: "New MA",
  url: "/accounts/ma/new",
  icon: <BarChartOutlined />
};

menuItem.acctCreate[sma] = {
  key: "new-ma",
  name: "New MA",
  url: "/accounts/ma/new",
  icon: <BarChartOutlined />
};

menuItem.acctCreate[ma] = {
  key: "new-AG",
  name: "New AG",
  url: "/accounts/ma/new",
  icon: <BarChartOutlined />
};

menuItem.acctCreate[ag] = {
  key: "new-mem",
  name: "New Mem",
  url: "/accounts/ma/new",
  icon: <BarChartOutlined />
};

menuItem.acctCreate[mem] = {
  key: "new-ma",
  name: "New AG",
  url: "/accounts/ma/new",
  icon: <BarChartOutlined />
};

const update = (level) => {
  return menuItem.acctCreate[level];
}

const { SubMenu } = Menu;

const SLMenu = ({ userLevel }) => {
  const { t } = useTranslation();

  menuItem.acctCreate[admin].name = t("NEW_MA");
  menuItem.acctCreate[sma].name = t("NEW_MA");

  const memberChild = [
    {
      key: "member-list",
      name: t("MEMBER_LIST"),
      icon: <FileDoneOutlined />,
      url: "/accounts/members"
    },
    // {
    //   key: "deposit",
    //   name: t("DEPOSIT"),
    //   icon: <FileDoneOutlined />,
    //   url: "/accounts/deposit"
    // },
    // {
    //   key: "deposit_info",
    //   name: t("DEPOSIT_INFO"),
    //   icon: <FileDoneOutlined />,
    //   url: "/accounts/deposit-info"
    // }
  ];

  const accountChild = [
    {
      key: "mailbox",
      name: t("MAILBOX"),
      icon: <ApiOutlined />,
      url: "/mailbox"
    },
    {
      key: "sign-out",
      name: t("SIGN_OUT"),
      icon: <ApiOutlined />,
      url: "/login"
    }
  ];

  if (userLevel <= 2) {
    memberChild.unshift(update(userLevel),
    {
      key: "account-list",
      name: t("ACCOUNT_LIST"),
      icon: <FileDoneOutlined />,
      url: "/accounts/downline"
    });
  }

  if (!userLevel) {
    accountChild.unshift({
      key: "api-list",
      name: t("API_LIST"),
      icon: <ApiOutlined />,
      url: "/api/list"
    });
  }

  const pages = [
    {
      key: "main-report-page",
      name: t("REPORTS"),
      icon: <FileDoneOutlined />,
      url: "/report",
      showOAgent: [admin,sma, ma, ag, mem],
      child: [
        {
          showOAgent: [admin],
          key: "live-report-page",
          name: t("LIVE_REPORT"),
          url: "/report/live",
          icon: <BarChartOutlined />
        },
        {
          key: "report-page",
          name: t("REPORT"),
          icon: <FileDoneOutlined />,
          url: "/report"
        },
        {
          key: "summary-page",
          name: t("SUMMARY"),
          icon: <FileDoneOutlined />,
          url: "/report/summary"
        }
      ]
    },
    {
      key: "main-member",
      name: t("MEMBER_MGMT"),
      icon: <UsergroupAddOutlined />,
      showOAgent: [admin, sma, ma, ag, mem],
      child: memberChild
    },
    // {
    //   key: "main-with-dep",
    //   name: "Widthdraw/Deposit",
    //   icon: <MoneyCollectOutlined />,
    //   url: "/report",
    //   showOAgent: [admin, sma, ma, ag, mem],
    //   child: [
    //     {
    //       key: "deposit-page",
    //       name: "Deposit",
    //       url: "/deposit",
    //       icon: <BarChartOutlined />
    //     },
    //     {
    //       key: "widthdraw-page",
    //       name: "Withdraw",
    //       icon: <FileDoneOutlined />,
    //       url: "/withdraw"
    //     }
    //   ]
    // },
    // {
    //   key: "setting-page",
    //   name: "Settings",
    //   icon: <SettingOutlined />,
    //   url: "/setting",
    //   showOAgent: [admin, ma, ag, mem],
    //   child: [
    //     {
    //       key: "symbols-page",
    //       name: "Symbols",
    //       icon: <RedditOutlined />,
    //       url: "/symbols"
    //     },
    //     {
    //       key: "paytable-page",
    //       name: "Pay Table",
    //       icon: <DollarCircleOutlined />,
    //       url: "/paytable"
    //     },
    //     {
    //       key: "payline-page",
    //       name: "Payline",
    //       icon: <AlignLeftOutlined />,
    //       url: "/payline"
    //     }
    //   ]
    // },
    {
      key: "account",
      name: t("ACCOUNT"),
      icon: <ApiOutlined />,
      showOAgent: [admin, sma, ma, ag, mem],
      child: accountChild
    },
    // ,
    // {
    //   key: "logout-page",
    //   name: "Logout",
    //   icon: <LogoutOutlined />,
    //   showOAgent: [sma, ma, ag, mem],
    //   url: "/login"
    // }
  ];

  return (
    <Menu defaultOpenKeys={['main-with-dep','main-member','main-report-page']} style={{ width: `${100}%` }} mode="inline" className="sl-menu" theme="dark" key="menu">
      {pages.map((item) => (
        item.showOAgent.includes(userLevel) && (
          item.child && item.child.length ? (

            <SubMenu key={item.key} title={item.name} icon={item.icon} className="sl-sub-menu">

              {item.child && item.child.map((sub) => (


               !sub.showOAgent ? (<Menu.Item key={sub.key} className="sl-sub-menu-item">

               <NavLink to={sub.url} activeClassName="selected">
                 {sub.name}
               </NavLink>

             </Menu.Item>) : (sub.showOAgent.includes(userLevel) && <Menu.Item key={sub.key} className="sl-sub-menu-item">

                  <NavLink to={sub.url} activeClassName="selected">
                    {sub.name}
                  </NavLink>

                </Menu.Item>)


              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item.key} icon={item.icon}>

              <NavLink to={item.url} activeClassName="selected">
                {item.name}
              </NavLink>

            </Menu.Item>
          )
        )
      ))}
    </Menu>
  );
};

export default SLMenu;
