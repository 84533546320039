import "./Radio.scss";

const Radio = ({ id, name, label, value, ...restProps }) => {
  return (
    <div className="app-radio">
      <input id={id} type="radio" name={name} value={value} {...restProps} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
};

export default Radio;