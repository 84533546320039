import React, { useContext, useState, createContext } from "react";

export const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
  const [info, setInfo] = useState({});

  return (
    <AuthContext.Provider value={{info, setInfo}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
