/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, Table, Button, Menu, Dropdown, Tag } from "antd";
import { DashOutlined } from "@ant-design/icons";

import State from "../../data";

import "./DistributedSymbol.scss";

const DistributedSymbol = () => {
  const history = useHistory();
  const handleBackHistoryOnClick = () => history.go(-1);
  const [dataSource, updateDataSource] = useState([]);
  const [tags, updateTags] = useState([]);
  const [distributedSymbols, updateDistributedSymbol] = useState(State.distributedSymbols);

  const loopReel = (cb) => {
    for (let i = 0; i < State.settings.number_of_reels; i++) {
      cb(i + 1);
    }
  };

  const createRow = useCallback((rowNumber, symbols) => {
    const newRow = {
      key: rowNumber,
      no: rowNumber
    };

    loopReel((reelNumber) => {
      newRow[`reel${reelNumber}`] = {
        number: rowNumber,
        symbol: symbols && symbols[reelNumber - 1]
      };
    });

    updateDataSource((arr) => [...arr, newRow]);
  });

  const handleAddRowOnClick = () => {
    const rowNumber = dataSource.length + 1;
    createRow(rowNumber);
  };

  const onSelectDropdownMenu = useCallback((symbol, reelNumber, rowNumber) => {
    updateDistributedSymbol((oldSymbols) => {
      const newDistributedSymbols = oldSymbols;

      newDistributedSymbols[rowNumber - 1][reelNumber - 1] = symbol.name;

      updateDataSource((oldData) => {
        const newData = oldData;

        newData[rowNumber - 1][`reel${reelNumber}`].symbol = symbol.name;

        return [...newData];
      });

      return newDistributedSymbols;
    });
  });

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 80,
      render: (text) => text
    }
  ];

  const menu = (reelNumber, rowNumber) => (
    <Menu>
      {State.symbols.map((symbol) => (
        <Menu.Item key={symbol.name} onClick={() => onSelectDropdownMenu(symbol, reelNumber, rowNumber)}>
          {symbol.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  loopReel((reelNumber) => {
    columns.push({
      title: `Reel ${reelNumber}`,
      dataIndex: `reel${reelNumber}`,
      key: `reel${reelNumber}`,
      render: (row) => (
        <Dropdown overlay={menu(reelNumber, row.number)}>
          <a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {row.symbol || <DashOutlined />}
          </a>
        </Dropdown>
      )
    });
  });

  useEffect(() => {
    distributedSymbols.forEach((value, index) => {
      createRow(index + 1, value);
    });
  }, []);

  useEffect(() => {
    const newTags = [];

    for (let j = 0; j < State.settings.number_of_reels; j++) {
      let symbolCount = 0;

      for (let i = 0; i < distributedSymbols.length; i++) {
        const symbol = distributedSymbols[i][j];

        if (symbol && symbol.length) symbolCount += 1;
      }

      const newTag = {
        reelNumber: j + 1,
        count: symbolCount
      };

      newTags.push(newTag);
    }

    updateTags(() => [...newTags]);
  }, [dataSource]);

  return (
    <div className="distributed-symbol__page">
      <PageHeader
        className="site-page-header"
        title="Distributed Symbols"
        subTitle="Configure symbols for slot game"
        onBack={handleBackHistoryOnClick}
      />

      <section className="button-group">
        <Button type="primary" onClick={handleAddRowOnClick}>
          Add a row
        </Button>
        <div className="tag-group">
          {tags.map((tag) => (
            <Tag key={tag.reelNumber}>
              <span>Reel {tag.reelNumber}</span> - <b>{tag.count}</b>
            </Tag>
          ))}
        </div>
      </section>

      <section>
        <Table bordered size="small" columns={columns} dataSource={dataSource} pagination={false} />
      </section>
    </div>
  );
};

export default DistributedSymbol;
