export default {
  APP_NAME: "Wow Admin",
  EN: "English",
  KO: "한국어",
  KH: "ខ្មែរ",
  LANGUAGE: "언어",
  USER_DETAILS: "유저 정보",
  CASINO_PRODUCT: "카지노 제품 (%)",
  SLOT_PRODUCT: "슬롯 제품 (%)",
  SLOTS: "슬롯",
  PREFIX: "마스터 코드",
  USERNAME: "아이디",
  MA_NAME: "마스터 이름",
  PASSWORD: "비밀번호",
  STATUS: "상태",
  LEVEL: "레벨",
  ACTIVE: "활성",
  INACTIVE: "비활성",
  CREATE: "추가",
  LOADING: "기다리세요",
  SUPER_MASTER_AGENT: "Super Master Agent",
  MASTER_AGENT: "Master Agent",
  AGENT: "대리인",
  LOGOUT: "로그 아웃",
  CHANGE_PASSWORD: "비밀번호 변경",
  ACCOUNT_LIST: "계정 목록",
  FILTER: "필터",
  INVALID_DATA: "활동 데이터 없음 / 표기할 활동 데이터 없음",
  ALL: "전체",
  OPEN: "열림",
  CLOSED: "닫힘",
  SUBMIT: "검색",
  YES: "사용중지",
  NO: "사용중",
  LOCK: "잠그다",
  UNLOCK: "잠김해제",
  LEAVE_BLANK_IF_NO_CHANGE: "변경 없으면 빈칸으로 두시면 됩니다",
  EDIT: "수정",
  USERNAME: "아이디",
  AG: "에이전트",
  SUSPEND: "중지",
  NAME: "이름",
  LAST_LOGIN_TIME: "마지막 로그인 시간",
  LAST_LOGIN_IP: "마지막 로그인 IP",
  NATION: "국가",
  REGISTERED_TIME: "등록된 시간",
  MEMO: "메모",
  AGENT_PREFIX: "에이전트접두사",
  NO_RECORD_FOUND: "기록이 없습니다!",
  COUNTRY: "국가",
  MEMBER_LIST: "회원 목록",
  ID: "ID",
  MEMBER_ID: "맴버 아이디",
  ACTION: "조작",
  KICK_PLAYER: "킥 플레이어",
  PLAYABLE: "할 수 있는",
  URL: "URL",
  API_LIST: "API 목록",
  METHOD: "Method",
  DESCRIPTION: "설명",
  COPIED: "복사",
  SEARCH: "검색",
  MAILBOX: "쪽지함",
  CREATE_MAIL: "메일 만들기",
  WRITE_YOUR_MESSAGE_HERE: "여기에 메시지를 작성하십시오 ...",
  SUBJECT: "제목",
  SEND: "보내다",
  DATE: "날짜",
  UPDATE: "Update",
  PLEASE_FILL_OUT_THE_FORM: "양식을 작성해 주십시오",
  HOME: "홈",
  MEMBER_MGMT: "사용자 관리t",
  DEPOSIT: "보증금",
  DEPOSIT_INFO: "보증금 정보",
  REPORTS: "보고서",
  ACCOUNT: "어카운트",
  SIGN_OUT: "로그 아웃",
  NEW_MA: "새 마스터 만들기",
  KEY_GRAPH: "주요 차트",
  INFORMATION: "시니어 잔고 정보",
  MAINTENANCE_STATUS: "점검 상태",
  CASH_BALANCE: "예상수익금",
  TOTAL_BALANCE: "미정산금",
  TOTAL_MA : "마스터 수 합계",
  TOTAL_AG: "에이전트 수 합계",
  TOTAL_MEMBER: "멤버 수 합계",
  FILTER: "필터",
  INVALID_PREFIX: "Invalid prefix",
  USER_ID: "사용자 ID",
  PROFILE: "프로필",
  API_KEY: "API 키",
  AG_CODE: "AG Code",
  AG_ID: "AG ID",
  ENABLE: "Enable",
  DISABLE: "Disable",
  PLAYING: "Playing",
  SELECT_GAME: "게임을 선택",
  SUMMARY: "요약",
  REPORT:"보고서",
  TOTAL: "총",
  WINLOSS: "승소",
  WAGER: "내기를 걸다",
  TURNOVER: "회전율",
  RTP: "RTP",
  HISTORY:"역사",
  PRODUCT_ID:"제품 ID",
  REFERENCE:"참조",
  BALANCE:"균형",
  CLOSING_BALANCE:"Closing Balance",
  GAME_ID:"게임 ID",
  NO_OF_PAYLINE:"NO. of Paylines",
  BET_TYPE:"Bet Type",
  WIN_LINES:"Win Lines",
  BET:"내기",
  WIN : "이기다",
  LOSE: "잃다",
  CANCEL: "취소",
  REFERENCE: "Reference",
  TOTAL_BET: "Total Bet",
  LIVE_REPORT: "라이브 보고서",
  ROUND_ID: "Round ID",
  ROUND_DETAIL: "Round Details",
  PENDING: "Pending",
  MORE: "More",
  EXPORT: "Export",
};
