import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from "moment";

import HTTPService from "../../services/HTTPService";
import SLBreadcrumb from "../../components/Breadcrumb";
import SLTable from "../../components/SLTable/index";
import Filter from "../../components/Filters/Filter";
import constants from "../../helpers/constants";
import { useAuth } from "../../hooks/auth";
import config from "../../config";

import App from "../../App";

import "./Mailbox.scss";

const state = constants.state;

const API_URL = process.env.REACT_APP_API_SERVER;

const fetchData = async (params, header, cb) => {
  const response = await HTTPService.get(API_URL, params, header);
  return cb(response);
};

const postData = async (params, header, cb) => {
  const response = await HTTPService.post(API_URL, params, header);
  return cb(response);
};

const FormInput = styled(Input)`
  max-width: ${({ subject }) => subject ? "300px" : "150px"}
`;

const CreatingMailModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: #00000042;
  overflow: hidden;
  animation: 0.2s fadeIn;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .modal-mail__wrapper {
    position: relative;
    top: 5em;
    width: 800px;
    max-height: 80vh;
    margin: auto;
    background-color: white;
    border: 1px solid #9f9f9f;

    header {
      width: 100%;
      padding: 1rem;
      color: #fff;
      background-color: #2C457E;
      text-transform: uppercase;
      font-weight: bold;

      a {
        float: right;
        margin-top: -3px;
        font-size: 1.1rem;
        color: white;
      }
    }

    section {
      padding: 1em;
    }

    footer {
      padding: 1em;

      .modal-mail__footer-button {
        text-align: right;
        margin-bottom: 14px;
      }
    }

    .modal-mail__plain-subject {
      font-weight: bold;
      margin-bottom: 12px;
    }

    .modal-mail__plain-message {
      height: 45vh;
      padding: 0.6rem 0.5rem;
      background-color: #e4e5e6;
      overflow: auto;
    }
  }
`;

const SendMailButton = styled(Button)`
  overflow: hidden;
`;

const UpdateMailButton = styled(Button)`
  overflow: hidden;
`;

const Mailbox = () => {
  const { t } = useTranslation();
  const { info: user } = useAuth();
  const [sendLoading, setSendLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, updateMessage] = useState({ type: state.NORMAL, description: "" });
  const [accountList, updateAccountList] = useState([]);
  const [selectedUsername, updateSelectedUsername] = useState();
  const [displayMailModal, setDisplayMailModal] = useState(false);
  const [dataSource, updateDataSource] = useState([]);
  const [selectedMail, updateSelectedMail] = useState();
  const [editor, setEditor] = useState();
  const originalDataSource = useRef();
  const mailMessageRef = useRef();
  const subjectRef = useRef();
  const searchRef = useRef();
  const nameRef = useRef();

  useEffect(() => {
    let gettingAccountListParams = {
      action: "GET_ACCOUNT_LIST",
      parent_username: user.username
    };

    fetchMails();

    fetchData(gettingAccountListParams, { "api-key": user.api_key }, (res) => {
      if (res && res.data) {
        const list = res.data
          .filter((row) => +row.level > +user.level)
          .map((row) => {
            return {
              label: row.username,
              value: row.username,
            }
          });

        if (list && list.length) {
          updateSelectedUsername(list[0].value);
          updateAccountList(list);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (message.type !== state.NORMAL) {
      let timer = setTimeout(() => {
        clearTimeout(timer);
        updateMessage({ type: state.NORMAL, description: "" });
      }, 5000);
    }
  }, [message]);

  const showDataTable = (data) => {
    if (data && data.length) {
      let rowNumber = 1;

      const list = data
        .filter((row) => !row.main_id)
        .map((row) => {
          return {
            id: rowNumber++,
            username: row.username,
            name: row.name,
            subject: { value: row.subject, rawData: row },
            date: moment(row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss")
          };
        });
    
      originalDataSource.current = data;
      updateDataSource(list);
    }

    setLoading(false);
  };

  const fetchMails = (cb) => {
    fetchData({ action: "GET_MAILBOX", ...user, orderBy: "DESC" }, { "api-key": user.api_key }, (res) => {
      if (res && res.data) {
        showDataTable(res.data);
        if (cb) return cb();
      }
    });
  };

  const handleSelectingAccount = (e) => {
    updateSelectedUsername(e.target.value);
  };

  const handleSubmit = () => {
    let inputValue = searchRef.current.input.value;

    if (inputValue && inputValue.length) {
      const newDataSource = dataSource
        .filter((data) => {
          let record = data.subject.rawData;
          let found = true;
  
          inputValue = inputValue.toLowerCase().trim();

          found = record.username.indexOf(inputValue) !== -1;
          found = found || record.name.toLowerCase().indexOf(inputValue) !== -1;
          found = found || record.subject.toLowerCase().indexOf(inputValue) !== -1;
          found = found || record.message.toLowerCase().indexOf(inputValue) !== -1;
          
          return found;
        });
  
      updateDataSource(newDataSource);
    } else {
      showDataTable(originalDataSource.current);
    }
  };

  const showMailModal = (mail) => {
    setDisplayMailModal(true);
    updateSelectedMail(mail);
    
    if (mail) {
      mail.message = `
      <b class="modal-mail__recipient">${mail.sender_name}</b>
      ${mail.message}
    `;

      mailMessageRef.current = mail.message;
      updateSelectedUsername(mail.username);
      updateSelectedMail(mail);

      /**
       * Mail replying to top agency
       */
      const replyMails = findReplyMails(mail.id);

      if (replyMails && replyMails.length) {
        const updatedMailMessage = replyMails.reduce((total, mail, index, array) => {
          if (index < array.length) {
            total += "<hr/>";
          }

          total += `<b class="modal-mail__recipient"modal-mail__recipient>${mail.sender_name}</b>`
          total += mail.message;

          return total;
        }, mail.message);

        updateSelectedMail({ ...mail, message: updatedMailMessage });
      }

      let timer = setTimeout(() => {
        clearTimeout(timer);
        scrollMailboxToBottom();
      }, 600);
    }
  };

  const scrollMailboxToBottom = () => {
    let objDiv = document.getElementsByClassName("modal-mail__plain-message");
    objDiv[0].scrollTop = objDiv[0].scrollHeight;
  };

  const closeCreatingMailModal = () => {
    setDisplayMailModal(false);
  };

  const sendAlertNewMail = async (mail) => {
    return await HTTPService.post(`${config.app.serverUrl}v1/game/newMail`, mail);
  };

  const sendMail = () => {
    setSendLoading(true);
    updateMessage({ type: state.NORMAL, description: "" });

    let newMail = {};

    if (selectedMail) {
      const receiver = selectedMail.sender_name === user.username ? selectedMail.username : selectedMail.sender_name;

      /**
       * Reply mail
       */
      newMail = {
        level: user.level,
        receiver: receiver,
        username: selectedMail.username,
        name: selectedMail.name,
        subject: selectedMail.subject,
        message: mailMessageRef.current,
        main_id: selectedMail.id,
        created_by: user.id
      };
    } else {
      /**
       * Create new mail
       */
      newMail = {
        level: user.level,
        receiver: selectedUsername,
        username: selectedUsername,
        name: nameRef.current.input.value,
        subject: subjectRef.current.input.value,
        message: mailMessageRef.current,
        created_by: user.id
      };
    }

    if (newMail.username && newMail.name && newMail.subject && newMail.message) {
      postData({ action: "CREATE_NEW_MAIL", ...newMail }, { "api-key": user.api_key }, (res) => {
        let messageAlert = { type: state.FAILED, description: res.message };

        if (res && res.status) {
          messageAlert = { type: state.SUCCESS };
        }

        if (selectedMail) {
          const mail = selectedMail;
          
          mail.message += `
            <hr/>
            <b class="modal-mail__recipient">${user.username}</b>
            ${newMail.message}
          `;

          updateSelectedMail(mail);
          scrollMailboxToBottom();
        }

        editor.setData('');

        updateMessage(messageAlert);

        let timer = setTimeout(() => {
          clearTimeout(timer);
          closeCreatingMailModal();
        }, 2000);

        fetchMails(() => {
          setSendLoading(false);
        });

        const sendMail = {
          name: newMail.name,
          subject: newMail.subject,
          message: newMail.message,
          sender: user.username,
          receiver: newMail.receiver,
        };

        sendAlertNewMail(sendMail);
      });
    } else {
      updateMessage({ type: state.FAILED, description: t("PLEASE_FILL_OUT_THE_FORM") });
      setSendLoading(false);
    }
  };

  const updateMail = () => {
    setSendLoading(true);
    updateMessage({ type: state.NORMAL, description: "" });

    const updatingMail = {
      id: selectedMail.id,
      name: nameRef.current.input.value,
      subject: subjectRef.current.input.value,
      message: mailMessageRef.current
    };

    if (updatingMail.name && updatingMail.subject && updatingMail.message) {
      postData({ action: "UPDATING_MAIL", ...updatingMail }, { "api-key": user.api_key }, (res) => {
        let messageAlert = { type: state.FAILED, description: res.message };

        if (res && res.status) {
          messageAlert = { type: state.SUCCESS };

          updateDataSource((mails) => {
            mails.forEach((mail) => {
              if (+mail.id === +updatingMail.id) {
                mail = Object.assign(mail, {
                  name: updatingMail.name,
                  message: updatingMail.message
                });

                mail.subject = updatingMail.subject;
              }
            });

            return [...mails];
          });
        }

        updateMessage(messageAlert);
        setSendLoading(false);
      });
    } else {
      updateMessage({ type: state.FAILED, description: t("PLEASE_FILL_OUT_THE_FORM") });
      setSendLoading(false);
    }
  };

  const findReplyMails = (replyTo) => originalDataSource.current
    .filter((mail) => +mail.main_id === +replyTo)
    .sort((a, b) => a.id - b.id);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("USERNAME"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("NAME"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("SUBJECT"),
      dataIndex: "subject",
      key: "subject",
      render: (record) => {
        return <a href="javascript:void(0)" onClick={showMailModal.bind(null, record.rawData)}>{record.value}</a>
      }
    },
    {
      title: t("DATE"),
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <div id="page__mailbox">
      <SLBreadcrumb items={[t("MAILBOX")]} style={{ marginTop: 15 }} />
      <Filter
        sData={[
          [
            {
              span: 8,
              key: "col-8",
              field: (
                <Form.Item label={t("SEARCH")}>
                  <FormInput ref={searchRef} />
                </Form.Item>
              )
            },
            {
              span: 3,
              key: "submit",
              field: <Button onClick={handleSubmit}>{t("SUBMIT")}</Button>
            },
            {
              span: 3,
              key: "creating-mail",
              field: <Button type="primary" onClick={() => showMailModal(null)}>{t("CREATE_MAIL")}</Button>
            },
          ]
        ]} />
      <Card>
        {
          displayMailModal && (
            <CreatingMailModal>
              <div className="modal-mail__wrapper">
                <header>
                  <span>{t("MAILBOX")}</span>
                  <a href="javascript:void(0)" onClick={closeCreatingMailModal}><CloseOutlined /></a>
                </header>
                {
                  selectedMail ? (
                    <section>
                      <div className="modal-mail__plain-subject">
                        <span>{t("SUBJECT")}:&nbsp;</span>
                        <span>{selectedMail && selectedMail.subject}</span>
                      </div>
                      <p className="modal-mail__plain-message" dangerouslySetInnerHTML={{ __html: selectedMail && selectedMail.message }}></p>
                      <CKEditor
                        editor={ ClassicEditor }
                        onChange={ ( event, CKEditor ) => {
                          mailMessageRef.current = CKEditor.getData();
                          setEditor(CKEditor);
                        } } />
                    </section>
                  ) : (
                    <section>
                      <Form.Item label={t("NAME")}>
                        <FormInput ref={nameRef} defaultValue={selectedMail && selectedMail.name} />
                      </Form.Item>
                      <Form.Item label={t("SUBJECT")}>
                        <FormInput subject="true" ref={subjectRef} defaultValue={selectedMail && selectedMail.subject} />
                      </Form.Item>
                      <Form.Item label={t("USERNAME")}>
                        {
                          selectedMail ? (
                            <App.Select
                              style={{ width: "120px" }}
                              defaultValue={selectedUsername}
                              onChange={handleSelectingAccount}
                              list={accountList}
                              disabled />
                          ) : (
                            <App.Select
                              style={{ width: "120px" }}
                              defaultValue={selectedUsername}
                              onChange={handleSelectingAccount}
                              list={accountList} />
                          )
                        }
                      </Form.Item>
                      <CKEditor
                        editor={ ClassicEditor }
                        onChange={ ( event, CKEditor ) => {
                          mailMessageRef.current = CKEditor.getData();
                          setEditor(CKEditor);
                        } } />
                    </section>
                  )
                }
                <footer>
                  <div className="modal-mail__footer-button">
                    <SendMailButton danger onClick={sendMail} loading={sendLoading}>{t("SEND")}</SendMailButton>
                    {/* <UpdateMailButton danger onClick={updateMail} loading={sendLoading}>{t("UPDATE")}</UpdateMailButton> */}
                  </div>
                  <App.MessageAlert type={message.type} description={message.description} />
                </footer>
              </div>
            </CreatingMailModal>
          )
        }
        <SLTable
          columns={columns}
          data={dataSource}
          loading={loading}
          pagination={{ pageSize: 500, position: ["topLeft", "bottomLeft"] }}
          bordered />
      </Card>
    </div>
  );
};

export default Mailbox;