/* eslint-disable arrow-body-style */

import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Alert } from "antd";

import utils from "../../helpers/utils";

import "./Login.scss";

const Login = () => {
  const [error, setError] = useState({ message: "", description: "", visible: false });
  const initialValues = { username: "", password: "", remember: true };

  if (window.sessionStorage && window.sessionStorage.credentials) {
    try {
      const credentials = JSON.parse(window.sessionStorage.getItem("credentials"));

      initialValues.username = credentials.username;
      initialValues.password = credentials.password;
      initialValues.remember = credentials.remember;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  const onLoginFailed = (values) => {
    const { username, password } = values;

    if (username && password) {
      setError({
        message: "Error",
        description: "Invalid username or password",
        visible: true
      });
    }
  };

  const onLogin = async (values) => {
    const response = await utils.login(values);

    if (response && response.status) {
      if (values.remember) sessionStorage.setItem("credentials", JSON.stringify(values));

      sessionStorage.setItem("sid", response.sid);
      window.location.pathname = "/report/summary";
    } else {
      onLoginFailed(values);
    }
  };

  return (
    <div id="login__page">
      <div className="login__form">
        {/* <h2>LOGIN</h2> */}

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          initialValues={{
            username: initialValues.username,
            password: initialValues.password,
            remember: initialValues.remember
          }}
          onFinish={onLogin}
          onFinishFailed={onLoginFailed}
          autoComplete="off">
          <Form.Item name="username" rules={[{ required: true, message: "Please input your username!" }]}>
            <Input placeholder="Username" />
          </Form.Item>
        
          <Form.Item name="password" rules={[{ required: true, message: "Please input your password!" }]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
{/* 
          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 16 }}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}

          <Form.Item >
            <Button className="btn-sumbit" htmlType="submit">
              LOGIN
            </Button>
          </Form.Item>
          {error.visible && <Alert message={error.message} description={error.description} type="error" />}
        </Form>
      </div>
    </div>
  );
};

export default Login;
