/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { initReactI18next } from "react-i18next";
import { Layout } from "antd";
import i18n from "i18next";
import styled from "styled-components";
import ChangePassword from "./pages/ChangePassword/index";
import Header from "./components/Header";
import SLMenu from "./components/SLMenu/index";
import AppComponent from "./components/App";

import SymbolList from "./pages/SymbolList/index";
import AddSymbol from "./pages/AddSymbol/index";
import DistributedSymbol from "./pages/DistributedSymbol/index";
import Setting from "./pages/Setting/index";
import PayTable from "./pages/PayTable/index";
import Payline from "./pages/Payline/index";
import Login from "./pages/Login/index";
import LiveReport from "./pages/LiveReport";
import { Deposit, Withdraw } from "./pages/WithdrawDeposit";
import { Summary, Report } from "./pages/Report/index";
import { AcctList, AcctDeposit, AcctDepositInfo, AccountCreation, MemberList } from "./pages/Account";
import Dashboard from "./pages/Dashboard";
import APIList from "./pages/API";
import Mailbox from "./pages/Mailbox";
import Profile from "./pages/Profile";

import { AuthProvider, useAuth } from "./hooks/auth";
import PrivateRoute from "./privateRoute";
import State from "./contexts/State";
import utils from "./helpers/utils";
import languages from "./i18n";

import "./App.scss";

const cachedLanguage = window.sessionStorage.getItem("current_language") || "en";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: languages,
    lng: cachedLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const Footer = styled.footer`
  padding: 0.6rem 1rem;
  background-color: white;
  border-top: 1px solid #e5e5e5;
  font-size: 0.8rem;
`;

function App() {
  const { Sider, Content } = Layout;
  const [currentPage, setCurrentPage] = useState("symbols-page");
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(cachedLanguage);

  if (localStorage.currentPage && localStorage.currentPage !== currentPage) {
    setCurrentPage(localStorage.currentPage);
  }

  const Logout = () => {
    setLoggedIn(false);
    sessionStorage.removeItem("sid");

    return null;
  };

  const ContentComponent = () => {
    let component = <Login />;

    const { info, setInfo } = useAuth();
    const userType = info.level;

    useEffect(async () => {
      try {
        const sessionID = sessionStorage.getItem("sid");
        const response = await utils.login({ sid: sessionID });

        setInfo(response);
        setLoggedIn(response && response.status === "success");
      } catch (err) {
        console.log(err);
      }
    }, []);

    if (isLoggedIn) {
      component = (
        <Layout>
          <Sider width={250} className="custom-layout-sider">
            <SLMenu userLevel={userType}/>
          </Sider>
          <div className="sidebar__black-border" />
          <Layout style={{ overflowY: "auto", }}>
            <Content style={{ padding: "0 20px", overflowY: "auto" }}>
              <Switch>
                <Route exact path="/symbols" component={SymbolList} />
                <PrivateRoute useLevel={userType} path="/symbols/add" isAuthenticated={[1].includes(userType)} component={AddSymbol} />
                <Route path="/symbols/dis-symbols" component={DistributedSymbol} />
                <PrivateRoute path="/paytable" isAuthenticated={[1].includes(userType)} component={PayTable} />
                <Route path="/payline" component={Payline} />
                <Route path="/setting" component={Setting} />
                <Route path="/login" component={Logout} />
                {/* <Route path="/report/live" component={LiveReport} /> */}
                <PrivateRoute path="/report/live" isAuthenticated={[0].includes(userType)} component={LiveReport} />
                <Route path="/report/summary" component={Summary} />
                <Route path="/report" component={Report} />
                <Route path="/deposit" component={Deposit} />
                <Route path="/withdraw" component={Withdraw} />
                <Route path="/accounts/downline" component={AcctList} />
                <Route path="/accounts/ma/new" component={AccountCreation} />
                <Route path="/accounts/deposit" component={AcctDeposit} />
                <Route path="/accounts/change-pass" component={ChangePassword} />
                <Route path="/accounts/deposit-info" component={AcctDepositInfo} />
                <Route path="/accounts/members" component={MemberList} />
                <Route path="/api/list" component={APIList} />
                <Route path="/mailbox" component={Mailbox} />
                <Route path="/profile" component={Profile} />
                {/* <Route path="/" component={Dashboard} /> */}
              </Switch>
            </Content>
            <Footer>Copyright © 2022</Footer>
          </Layout>
        </Layout>
      );
    } else if (isLoggedIn === null) {
      component = <div />;
    }

    return component;
  };

  return (
    <AuthProvider>
      <State.Provider value={{ currentLanguage, setCurrentLanguage }}>
        <Router>
          <div className="App">
            {isLoggedIn && <Header />}
            <ContentComponent />
          </div>
        </Router>
      </State.Provider>
    </AuthProvider>
  );
}

App.Select = AppComponent.Select;
App.Dropdown = AppComponent.Dropdown;
App.RadioGroup = AppComponent.RadioGroup;
App.MessageAlert = AppComponent.MessageAlert;
App.Radio = AppComponent.Radio;

export default App;
