import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LeftOutlined, LoadingOutlined, RightOutlined, EllipsisOutlined } from "@ant-design/icons";

import "./pagination.scss";

const Pagination = ({ current, pageSize, total, onChange, onFetchMore, onPrevious, onNext, onLeftExpand, onRightExpand, showMoreButton, isLoading }) => {
  const { t } = useTranslation();
  const totalPages = Math.ceil(total / pageSize);
  const [paginations, updatePaginations] = useState(null);
  const [showLeftExpanded, setShowLeftExpanded] = useState(false);
  const [showRightExpanded, setShowRightExpanded] = useState(false);
  const [showMiddlePaginations, setShowMiddlePaginations] = useState(false);

  const onClickPrevious = useCallback(() => {
    onPrevious(totalPages);
  }, [totalPages]);

  const onClickNext = useCallback(() => {
    onNext(totalPages);
  }, [totalPages]);

  const leftExpandMore = useCallback(() => {
    if (paginations) {
      let from = current - current % 5 - 5;
      let to = from + 5;
      let pages = [];

      for (let i = from; i <= to && i < totalPages; i++) {
        pages.push(i);
      }

      let updatedPaginations = paginations;

      updatedPaginations.values.first = pages[0];
      updatedPaginations.values.middle = pages;
      updatedPaginations.values.last = pages[pages.length - 1];

      updatePaginations(updatedPaginations);
      onLeftExpand(updatedPaginations.values.first);
    }
  }, [paginations, current, totalPages, updatePaginations, onLeftExpand]);

  const rightExpandMore = useCallback(() => {
    if (paginations) {
      let from = current - current % 5 + 5 + 1;
      let to = from + 4;
      let pages = [];

      for (let i = from; i <= to && i < totalPages; i++) {
        pages.push(i);
      }

      let updatedPaginations = paginations;

      updatedPaginations.values.first = pages[0];
      updatedPaginations.values.middle = pages;
      updatedPaginations.values.last = pages[pages.length - 1];

      updatePaginations(updatedPaginations);
      onRightExpand(updatedPaginations.values.first);
    }
  }, [paginations, current, totalPages, updatePaginations, onRightExpand]);

  useEffect(() => {
    if (totalPages > 0) {
      const pages = Array.from(Array(totalPages).keys()).map(value => value + 1);

      const updatedPaginations = {
        first: pages[0],
        last: pages[pages.length - 1],
        values: {},
      };

      let middleItems = pages;

      if (totalPages >= 5) {
        if (current <= 5) {
          middleItems = pages.slice(1, totalPages <= 5 ? 4 : 5);
        } else if (updatedPaginations.last - current < 5) {
          middleItems = pages.slice(updatedPaginations.last - 5, updatedPaginations.last - 1);
        } else {
          middleItems = pages.slice(current - 1, current + 4);
        }
      } else {
        middleItems = pages.slice(1, totalPages - 1);
      }
      
      updatedPaginations.values.first = middleItems[0];
      updatedPaginations.values.middle = middleItems;
      updatedPaginations.values.last = middleItems[middleItems.length - 1];

      updatePaginations(updatedPaginations);
    }
  }, [totalPages, current, updatePaginations]);

  useEffect(() => {
    let expanded = paginations && paginations.values;

    if (expanded) {

      if (paginations.first) {
        expanded = paginations.values.first - paginations.first >= 2;
        setShowLeftExpanded(expanded);
      }
  
      if (paginations.last) {
        expanded = (paginations.last - paginations.values.last) >= 2;
        setShowRightExpanded(expanded);
      }
    }

    setShowMiddlePaginations(
      total && paginations && paginations.values.middle && paginations.values.middle.length
    );
  }, [current, paginations, setShowLeftExpanded, setShowRightExpanded, setShowMiddlePaginations]);

  return (
    <ul className="pagination">
      <li
        className={`pagination__link pagination__prev ${!total || current === 1 ? "disabled" : "enabled"}`}
        onClick={onClickPrevious}>
        <LeftOutlined />
      </li>

      {
        paginations && paginations.first ? (
          <li
            key={paginations.first}
            className={current === paginations.first ? "active" : ""}
            onClick={() => onChange(paginations.first, pageSize)}>
            {paginations.first}
          </li>
        ) : null
      }

      {
        showLeftExpanded ? (
          <>
            <li onClick={leftExpandMore}>
              <EllipsisOutlined />
            </li>
          </>
        ) : null
      }

      {
        showMiddlePaginations ? (
          paginations.values.middle.map((number) => {
            return (
              <li
                key={number}
                className={current === number ? "active" : ""}
                onClick={() => onChange(number, pageSize)}>
                <a>{number}</a>
              </li>
            );
          })
        ) : null
      }

      {
        showRightExpanded ? (
          <>
            <li onClick={rightExpandMore}>
              <EllipsisOutlined />
            </li>
          </>
        ) : null
      }

      {
        paginations && paginations.last && paginations.first !== paginations.last ? (
          <li
            key={paginations.last}
            className={current === paginations.last ? "active" : ""}
            onClick={() => onChange(paginations.last, pageSize)}>
            {paginations.last}
          </li>
        ) : null
      }

      {
        showMoreButton ? (
          <li className="pagination__fetch-more" onClick={onFetchMore}>
            {
              isLoading ? <LoadingOutlined /> : t("MORE")
            }
          </li>
        ) : null
      }

      {
        !total ? <li>1</li> : null
      }

      <li
        className={`pagination__link pagination__next ${!total || current === totalPages ? "disabled" : "enabled"}`}
        onClick={onClickNext}>
        <RightOutlined />
      </li>
    </ul>
  );
};

export default Pagination;