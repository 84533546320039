import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { PlayCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import beautify from "json-beautify";
import copy from "copy-to-clipboard";

import SLBreadcrumb from "../../components/Breadcrumb";
import Filter from "../../components/Filters/Filter";
import SLTable from "../../components/SLTable/index";
import HTTPService from "../../services/HTTPService";
import constants from "../../helpers/constants";
import { useAuth } from "../../hooks/auth";

import App from "../../App";

import "./APIList.scss";

const API_URL = process.env.REACT_APP_API_SERVER;

const state = constants.state;

const FormInput = styled(Input)`
  max-width: 150px;
`;

const APIResponse = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  border: 2px solid #efefef;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;

  header {
    padding: 0.2rem 0.8rem;
    font-weight: bold;
    background-color: #eaeaea;
  }

  pre {
    flex: 1;
    position: relative;
    padding: 0.8rem;
    background-color: #f5f5f5;
    margin-bottom: 0;
  }
`;

const Copy = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc600;
  font-weight bold;
  font-size: 2rem;
  color: white;
  opacity: 0.6;
  animation: 3.6s fadeOut;
  animation-fill-mode: forwards;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const fetchData = async (params, header, cb) => {
  const response = await HTTPService.get(API_URL, params, header);
  return cb(response);
};

const APIList = () => {
  const { t } = useTranslation();
  const { info: user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, updateMessage] = useState({ type: state.NORMAL, description: "" });
  const [dataSource, updateDataSource] = useState([]);
  const [apiResponse, updateAPIResponse] = useState();
  const [copied, setCopied] = useState(false);
  const originalDataSource = useRef();
  const searchRef = useRef();

  useEffect(() => {
    fetchData({ action: "GET_API_LIST" }, { "api-key": user.api_key }, (res) => {
      if (res && res.data) {
        let rowNumber = 1;

        const list = res.data.map((api) => {
          return {
            id: rowNumber++,
            url: api.url,
            method: api.method.toUpperCase(),
            description: api.description,
            action: api
          }
        });

        originalDataSource.current = list;
        updateDataSource(list);
      }
    });
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      clearTimeout(timer);
      setCopied(false);
    }, 8000);
  }, [copied]);

  useEffect(() => {
    if (message.type !== state.NORMAL) {
      let timer = setTimeout(() => {
        clearTimeout(timer);
        updateMessage({ type: state.NORMAL, description: "" });
      }, 5000);
    }
  }, [message]);

  const callAPI = async (data) => {
    setLoading(true);
    updateMessage({ type: state.NORMAL, description: "" });

    let url = data.url;
    let header = {};
    let params = {};

    switch (data.condition) {
      case "require_api_key":
        header["api-key"] = user.api_key;
        break;
    }
      
    const response = await HTTPService[data.method.toLowerCase()](url, params, header);
    const popupMessage = { type: state.FAILED, description: response.message };

    if (response && response.status) {
      popupMessage.type = state.SUCCESS;

      if (response.data) {
        updateAPIResponse({
          raw: response.data,
          beautified: beautify(response.data, null, 2, 80)
        });
      } else {
        updateAPIResponse(null);
      }
    }

    updateMessage(popupMessage);
    setLoading(false);
  };

  const onCopy = (data) => {
    copy(JSON.stringify(data));
    setCopied(true);
  };

  const handleSubmit = () => {
    let inputValue = searchRef.current.input.value;

    const newDataSource = originalDataSource.current
      .filter((data) => {
        let found = true;

        if (inputValue.length) {
          inputValue = inputValue.toLowerCase().trim();

          found = data.url.indexOf(inputValue) !== -1;
          found = found || data.method.toLowerCase().indexOf(inputValue) !== -1;
        }
        
        return found;
      });

    updateDataSource(newDataSource);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("URL"),
      dataIndex: "url",
      key: "url",
    },
    {
      title: t("METHOD"),
      dataIndex: "method",
      key: "method",
    },
    {
      title: t("DESCRIPTION"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("ACTION"),
      dataIndex: "action",
      key: "action",
      render: (record) => {
        return <Button onClick={callAPI.bind(null, record)}><PlayCircleOutlined /></Button>
      }
    },
  ];

  return (
    <div id="page__api-list">
      <SLBreadcrumb items={[t("API_LIST")]} style={{ marginTop: 15 }} />
      <Filter
        sData={[
          [
            {
              span: 8,
              key: "col-8",
              field: (
                <Form.Item label={t("SEARCH")}>
                  <FormInput ref={searchRef} />
                </Form.Item>
              )
            },
            {
              span: 4,
              key: "col-4",
              field: <Button onClick={handleSubmit}>{t("SUBMIT")}</Button>
            },
          ]
        ]} />
      <Card>
        <div className="page__card_wrapper">
          <SLTable
            columns={columns}
            data={dataSource}
            pagination={{ position: ["topLeft", "bottomLeft"] }}
            loading={loading}
            bordered />
          { apiResponse && (
            <APIResponse onClick={onCopy.bind(null, apiResponse.raw)}>
              <header>{t("RESPONSE MESSAGE")}</header>
              <pre>
                { copied && <Copy>{t("COPIED")}</Copy> }
                {apiResponse.beautified}
              </pre>
            </APIResponse>
          ) }
        </div>
        <App.MessageAlert type={message.type} description={message.description} />
      </Card>
    </div>
  )
};

export default APIList;