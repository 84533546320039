/* eslint-disable no-case-declarations */

import React, { useState, useEffect } from "react";
import { PageHeader, Tag, Statistic, Card, Row, Col, Tooltip } from "antd";
import io from "socket.io-client";
import moment from "moment";
import { t } from "i18next";

import config from "../../config";
import utils from "../../helpers/utils";
import { useAuth } from "../../hooks/auth";
import SLTable from "../../components/SLTable/index";

import "./LiveReport.scss";

const LiveReport = () => {
  const [socket, setSocket] = useState(null);
  const [dataSource, updateDataSource] = useState([]);
  const [userWinAmount, updateUserWinAmount] = useState(0);
  const [userBetAmount, updateUserBetAmount] = useState(0);
  const [numberOfBets, updateNumberOfBets] = useState(0);
  const [players, updatePlayers] = useState({});
  const { info: user } = useAuth();

  let numberOfRows = 0;

  useEffect(() => {
    const url = config.app.serverUrl.replace("http:", "");
    const newSocket = io(url, { query: `ssid=${sessionStorage.getItem("sid")}` });

    setSocket(newSocket);

    newSocket.on("message", (message) => {
      if (user.level) {
        if (!message.group_parent_id) message.group_parent_id = "";

        if (user.name !== message.agc && !message.group_parent_id.split(",").map(num => +num).includes(+user.id)) {
          return;
        }
      }

      switch (message.e) {
        case "mb":
          numberOfRows++;

          updateUserBetAmount((currentAmount) => +currentAmount + +message.bet);

          updatePlayers((players) => {
            players[message.usn] = true;
            return players;
          });

          updateDataSource((arr) => {
            let reference = message.ref;

            if (reference) reference = reference.slice(0, 3) + "..." + reference.slice(reference.length - 3);

            const newBet = {
              key: utils.generateRandomKey(),
              id: numberOfRows,
              round_id: message.rid,
              round_details: message.rdt,
              ag_code: message.agc,
              ag_prefix: message.agp && message.agp.length && message.agp.split(",").join(""),
              username: message.usn,
              currency: message.cur,
              opening_balance: message.obal,
              closing_balance: message.cbal,
              game_id: message.gid,
              num_payline: message.npl,
              num_win_line: message.wl,
              amount: message.bet,
              win: message.w,
              ref: <Tooltip title={message.ref}>{reference}</Tooltip>,
              date: moment(+message.cat * 1000).format("DD/MM/YYYY HH:mm:ss A"),
              status: createStatusTag(message.st)
            };

            return [newBet, ...arr].slice(0, 200);
          });

          updateNumberOfBets(numberOfRows);

          break;

        case "result":
          updateUserWinAmount((currentAmount) => +currentAmount + +message.w);

          updateDataSource((arr) => {
            const newSource = arr.map((item) => {
              if (item.round_id === message.rid) {
                item.closing_balance = message.cbal;
                item.status = createStatusTag(message.st);
                item.win = message.w;
              }

              return item;
            });

            return newSource;
          });

          break;

        case "cc":

          updateDataSource((arr) => {
            const newSource = arr.map((item) => {
              if (item.round_id === message.rid) {
                item.closing_balance = message.cbal;
                item.status = createStatusTag(message.st);
                item.win = message.w;
              }

              return item;
            });

            return newSource;
          });

          break;

        case "err":
          
          updateDataSource((arr) => {
            arr.forEach((item) => {
              const modifiedItem = item;

              if (modifiedItem.round_id === message.round_id) {
                modifiedItem.cancelled = true;
                modifiedItem.status = <Tag>{t("CANCEL")}</Tag>;
              }
            });

            return [...arr];
          });

          break;

        default:
          break;
      }
    });

    return () => newSocket.close();
  }, []);

  const createStatusTag = (value) => {
    const status = { color: "" };

    if (value === 0) {
      status.color = "orange";
      status.text = "Pending";
    } else if (value === 1) {
      status.color = "green";
      status.text = "Win";
    } else if (value === 2) {
      status.color = "red";
      status.text = "Lose";
    } else if (value === 3) {
      status.text = "Cancel";
    }

    return <Tag color={status.color}>{status.text}</Tag>;
  };

  const getRTP = () => {
    const rtp = +userWinAmount / +userBetAmount;
    return (rtp * 100) || 0;
  };

  const getNumberOfPlayers = () => {
    return Object.keys(players).length;
  };

  const renderColumn = (value, record) => {
    return record && record.cancelled ? <span className="text-red">{value}</span> : value;
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "3%",
      render: renderColumn
    },
    // {
    //   title: "Product ID",
    //   dataIndex: "product_id",
    //   key: "product_id",
    //   width: "6%",
    //   render(text) {
    //     return {
    //       props: { style: { fontWeight: "bold" } },
    //       children: text
    //     };
    //   }
    // },
    {
      title: "Round ID",
      dataIndex: "round_id",
      key: "round_id",
      width: "6%",
      render: renderColumn
    },
    {
      title: "Round Details",
      dataIndex: "round_details",
      key: "round_details",
      width: "5%",
      render: renderColumn
    },
    {
      title: t("REFERENCE"),
      dataIndex: "ref",
      key: "ref",
      render(text, record) {
        return {
          props: { style: { fontWeight: "bold", color: record && record.cancelled ? "#cf1322" : "black" }},
          children: text,
        };
      }
    },
    {
      title: t("AG_CODE"),
      dataIndex: "ag_code",
      key: "ag_code",
      width: "6%",
      render: renderColumn
    },
    {
      title: "AG Prefix",
      dataIndex: "ag_prefix",
      key: "ag_prefix",
      width: "6%",
      render: renderColumn
    },
    {
      title: t("USER_ID"),
      dataIndex: "username",
      key: "username",
      width: "6%",
      render: renderColumn
    },
    {
      title: "Game ID",
      dataIndex: "game_id",
      key: "game_id",
      width: "6%",
      render: renderColumn
    },
    // {
    //   title: "Currency",
    //   dataIndex: "currency",
    //   key: "currency",
    //   width: "3%"
    // },
    {
      title: "Balance",
      dataIndex: "opening_balance",
      key: "opening_balance",
      width: "8%",
      render: renderColumn
    },
    {
      title: "Closing Balance",
      dataIndex: "closing_balance",
      key: "closing_balance",
      width: "8%",
      render: renderColumn
    },
    {
      title: "No. of Paylines",
      dataIndex: "num_payline",
      key: "num_payline",
      width: "4%",
      render: renderColumn
    },
    // {
    //   title: "Bet Type",
    //   dataIndex: "bet_type",
    //   key: "bet_type",
    //   width: "6%",
    //   render(text) {
    //     let betTypeText = "normal";
    //     let className = "text-red";

    //     if (text === 1) {
    //       className = "text-black";
    //     } else if (text === 2) {
    //       betTypeText = "freespin";
    //       className = "text-silver";
    //     }

    //     return {
    //       props: { className },
    //       children: betTypeText
    //     };
    //   }
    // },
    {
      title: "Win Lines",
      dataIndex: "num_win_line",
      key: "num_win_line",
      width: "4%",
      render: renderColumn
    },
    {
      title: "Bet",
      dataIndex: "amount",
      key: "amount",
      width: "8%",
      render: renderColumn
    },
    {
      title: "Win",
      dataIndex: "win",
      key: "win",
      width: "8%",
      render: renderColumn
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "10%",
      render: renderColumn
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "6%",
      render: renderColumn
    }
  ];

  return (
    <div id="live-report__page">
      <PageHeader className="site-page-header" title="Live Report" />

      <section className="live-report__statistic">
        <Card>
          <Row gutter={16}>
            <Col span={3}>
              <Statistic title="No. of Players" value={getNumberOfPlayers()} />
            </Col>
            <Col span={3}>
              <Statistic title="No. of Bets" value={numberOfBets} />
            </Col>
            <Col span={4}>
              <Statistic title="Total User Bet" value={userBetAmount} precision={2} />
            </Col>
            <Col span={4}>
              <Statistic title="Total User Win" value={userWinAmount} precision={2} />
            </Col>
            <Col span={4}>
              <Statistic title="RTP" value={getRTP()} precision={4} />
            </Col>
          </Row>
        </Card>
      </section>

      <section>
        <SLTable
          bordered
          size="small"
          columns={columns}
          data={dataSource}
          pagination={{ pageSize: 200, size: "small" }}
        />
      </section>
    </div>
  );
};

export default LiveReport;
