import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Form, Input, Badge } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import io from "socket.io-client";

import SLBreadcrumb from "../../components/Breadcrumb";
import Filter from "../../components/Filters/Filter";
import SLTable from "../../components/SLTable/index";
import HTTPService from "../../services/HTTPService";
import constants from "../../helpers/constants";
import { useAuth } from "../../hooks/auth";
import config from "../../config";

import App from "../../App";

import "./MemberList.scss";

const API_URL = process.env.REACT_APP_API_SERVER;

const state = constants.state;

const statusType = {};

statusType[statusType["enable"] = 1] = "enable";
statusType[statusType["disable"] = 0] = "disable";

const fetchData = async (params, header, cb) => {
  const response = await HTTPService.get(API_URL, params, header);
  return cb(response);
};

const postData = async (params, header, cb) => {
  const response = await HTTPService.post(API_URL, params, header);
  return cb(response);
};

const FormInput = styled(Input)`
  max-width: 150px;
`;

const UpdatePlayer = styled(Button)`
  padding: 5px;
  height: 25px;
  line-height: 0;
  font-size: 12px;
`;

const KickPlayer = styled(Button)`
  padding: 5px;
  height: 25px;
  line-height: 0;
  font-size: 12px;
`;

const PlayableButton = styled(Button)`
  padding: 5px;
  height: 25px;
  line-height: 0;
  font-size: 12px;
  background-color: #ffaaaa;
`;

const Status = styled.div`
  display: flex;
  justify-content: space-between;
  width: 120px;

  .status__number {
    font-weight: bold;
  }

  .status__number--online {
    color: green;
  }
`;

const MemberList = () => {
  const { t } = useTranslation();
  const { info: user } = useAuth();
  const [dataSource, updateDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, updateMessage] = useState({ type: state.NORMAL, description: "" });
  const [status, setStatus] = useState("all");
  const [socket, setSocket] = useState("all");
  const [numberOfOnline, updateNumberOfOnline] = useState(0);
  const [numberOfPlayers, updateNumberOfPlayers] = useState(0);
  const originalDataSource = useRef();
  const searchRef = useRef();

  useEffect(() => {
    const url = config.app.serverUrl.replace("http:", "");

    fetchData({
      action: "GET_MEMBER_LIST",
      id: user.id,
      username: user.username,
      level: user.level
    }, { "api-key": user.api_key }, (res) => {
      if (res) {
        originalDataSource.current = res.data;
        updateDataSource(formatDataSource(res.data));
        setLoading(false);
      }
    });

    const newSocket = io(url, { query: `ssid=${sessionStorage.getItem("sid")}` });

    setSocket(newSocket);

    newSocket.on("message", (message) => {
      if (message.error) return;

      switch (message.e) {
        case "mb":
          message.reason = "NEW_BET";

        case "py":
          setActivePlayer(message);

          break;

        default:
          break;
      }
    });

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (message.type !== state.NORMAL) {
      let timer = setTimeout(() => {
        clearTimeout(timer);
        updateMessage({ type: state.NORMAL, description: "" });
      }, 5000);
    }
  }, [message]);

  useEffect(() => {
    if (originalDataSource.current && originalDataSource.current.length) {
      updateNumberOfPlayers(originalDataSource.current.length);
      updateNumberOfOnline(originalDataSource.current.filter((data) => +data.active).length);
    }
  }, [originalDataSource.current]);

  const formatDataSource = (data) => {
    let rowNumber = 1;

    return data.map((member) => {
      return {
        id: rowNumber++,
        agent: member.agn_username,
        member_id: member.id,
        username: member.user_id,
        active: member.active,
        prefix: member.ag_prefix && member.ag_prefix.replace(/,/gi, "").toUpperCase() + member.user_id,
        action: {
          agent: member.agn_username,
          username: member.user_id,
          member_id: member.id,
          status: member.status,
          level: member.level
        }
      };
    });
  };

  const handleSubmit = () => {
    let inputValue = searchRef.current.input.value;

    const members = originalDataSource.current
      .filter((data) => {
        let filtered = status === "all";

        if (!filtered) {
          if (+status === 2) {
            filtered = !!+data.active;
          } else if (+status <= 1) {
            filtered = +data.status === +status;
          }
        }

        return filtered;
      })
      .filter((data) => {
        let found = true;

        inputValue = inputValue.toLowerCase().trim();

        found = data.user_id.toLowerCase().trim().indexOf(inputValue) !== -1;
        found = found || data.agn_username.toLowerCase().trim().indexOf(inputValue) !== -1;

        return found;
      });

    updateNumberOfPlayers(members.length);
    updateDataSource(formatDataSource(members));
  };

  const handlePlayer = (player) => {
    setLoading(true);
    updateMessage({ type: state.NORMAL, description: "" });

    const updatedStatus = +!player.status;

    postData({
      action: "UPDATE_PLAYER_STATUS",
      id: player.member_id,
      status: updatedStatus,
    }, { "api-key": user.api_key }, (res) => {
      let messageType = state.FAILED;

      if (res && res.status) {
        const members = originalDataSource.current
          .map((data) => {
            if (+data.id === +player.member_id) {
              data.status = updatedStatus;
            }

            return data;
          });

        messageType = state.SUCCESS;
        updateDataSource(formatDataSource(members));
        setLoading(false);
      }

      updateMessage({ type: messageType });
    });
  };

  const onStatusChange = (evt) => {
    setStatus(evt.target.value);
  };

  const setActivePlayer = (player) => {
    originalDataSource.current = originalDataSource.current
      .map((data) => {
        data.active = player.reason !== "SESSION_CLOSED" && player.usn === data.user_id && player.agc === data.agn_username;

        if (data.active) data.game_id = player.game_id;

        return data;
      });

    updateDataSource(formatDataSource(originalDataSource.current));
  };

  const kickPlayer = (id) => {
    const player = originalDataSource.current.find((data) => +data.id === +id);

    if (player && player.active) {
      fetchData({
        action: "GET_AUTH_TOKEN_BY_AGENT",
        agent: player.agn_username,
        level: player.level,
      }, { "api-key": user.api_key }, (result) => {
        if (result) {
          HTTPService.post(`${config.app.serverUrl}v1/game/closeSession`, {
            user_id: player.user_id,
            game_id: player.game_id,
          }, {
            "ag-code": player.agn_username,
            "ag-token": result.auth_token
          });
        }
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("AG_CODE"),
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: t("USERNAME"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("AGENT_PREFIX"),
      dataIndex: "prefix",
      key: "prefix",
    },
    {
      title: t("STATUS"),
      dataIndex: "active",
      key: "active",
      render: (active) => {
        return active ? (
          <span>
            <Badge status="success" />
            { t("ONLINE") }
          </span>
        ) : (
          <span>
            <Badge status="error" />
            { t("OFFLINE") }
          </span>
        )
      }
    },
    {
      title: t("ACTION"),
      dataIndex: "action",
      key: "action",
      render: (record) => {
        return record && (
          <>
            {
              record.status ? (
                <UpdatePlayer onClick={handlePlayer.bind(null, record, 0)}>{t("DISABLE")}</UpdatePlayer>
              ) : (
                <PlayableButton onClick={handlePlayer.bind(null, record, 1)}>{t("ENABLE")}</PlayableButton>
              )
            }
            <KickPlayer onClick={kickPlayer.bind(null, record.member_id)}>{t("KICK_PLAYER")}</KickPlayer>
          </>
        );
      }
    },
  ];

  return (
    <div id="page__member-list">
      <SLBreadcrumb items={[t("MEMBER_LIST")]} style={{ marginTop: 15 }} />
      <Filter
        sData={[
          [
            {
              span: 9,
              key: "col-9",
              field: (
                <Form.Item label={t("SEARCH")}>
                  <FormInput ref={searchRef} />
                </Form.Item>
              )
            },
            {
              span: 7,
              key: "col-7",
              field: (
                <Form.Item label={t("STATUS")}>
                  <App.Select
                    defaultValue={status}
                    style={{ width: "120px" }}
                    onChange={onStatusChange}
                    list={[
                      {
                        label: t("ALL"),
                        value: "all"
                      },
                      {
                        label: t("DISABLE"),
                        value: 0
                      },
                      {
                        label: t("ENABLE"),
                        value: 1
                      },
                      {
                        label: t("ONLINE"),
                        value: 2
                      },
                    ]} />
                </Form.Item>
              )
            },
            {
              span: 4,
              key: "col-4",
              field: <Button onClick={handleSubmit}>{t("SUBMIT")}</Button>
            },
          ]
        ]} />
      <Card>
        <Status>
          <span>{t("TOTAL")}: <span className="status__number status__number--all">{numberOfPlayers}</span></span>
          <span>{t("ONLINE")}: <span className="status__number status__number--online">{numberOfOnline}</span></span>
        </Status>
        <SLTable
          columns={columns}
          data={dataSource}
          loading={loading}
          pagination={{ pageSize: 500, position: ["topLeft", "bottomLeft"] }}
          bordered />
        <App.MessageAlert type={message.type} description={message.description} />
      </Card>
    </div>
  );
};

export default MemberList;