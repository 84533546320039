import React,{useEffect,useState} from "react"
import {CChart} from "@coreui/react-chartjs"
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.min.css'
import '@coreui/coreui/dist/css/coreui.min.css'
import HTTPService from "../../services/HTTPService"
import {useAuth} from '../../hooks/auth';
const API_URL = process.env.REACT_APP_API_SERVER;
const Chart = ({chart}) => {
  const {info} = useAuth();
  const datalabels = ["jan", 'February', 'March', 'April', 'May', 'June', 'July'];
  const [chartData,setChartData] = useState([]);
  const [labels,setLabels] = useState();
  // datalabels.map((item,i) => {
  //   labels.unshift(moment().subtract(i,'days').format("YYYY-MM-DD"));
  // });

  const fetchData = async (url,param,header,cb) => {
       let res = await HTTPService.get(url,param,header);
        cb(null,res);
  }

  useEffect(() => {
    fetchData(API_URL,chart.apiParam,{"api-key": info.api_key},(err,res) => {
        if(err) throw err;
        let datas = res.data;
        if(res && res.data && datas.length) {
          let data = []
          let label = [];

          for(let i = 0; i <datas.length;i++){
            let {turnover,winloss} = datas[i];
            if(turnover){
              data.push(turnover);
            } else {
              data.push(winloss);
            }

            label.push(moment(datas[i].t * 1000).format("YYYY-MM-DD"));
          }
          setChartData(data);
          console.log(data,'data');
          setLabels(label);
        }
    });
  },[])

  return (<CChart
    type="bar"
    data={{
      labels: labels,
      datasets: [
        {
          label: chart.title,
          backgroundColor: '#94d3f1',
          barThickness: 20,
          base: chart.base || 0,
          hoverBackgroundColor: '#57beee',
          data: chartData,
        },
      ],
    }}
   options={{scales: {
    x: {
      ticks: {
        padding:10,
      }

    }
    ,
    y : {
      ticks: {
        padding: 10
      }
    }

  }}}
    labels="months"
  />

  )
}


export default Chart;
