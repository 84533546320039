export default {
  PaylineTab: {
    LINE: "LINE",
    SCATTER: "SCATTER"
  },

  userTypes: {
    admin: 0,
    sma: 1,
    ma: 2,
    ag: 3,
    mem: 4
  },

  userTypeAsString: {
    0: "admin",
    1: "sma",
    2: "ma",
    3: "ag",
    4: "mem"
  },

  state: {
    NORMAL: 0,
    SUCCESS: 1,
    FAILED: 2
  },

  SOCKET_IO_MESSAGE_TYPE: {
    NEW_MAIL: "new_mail"
  }
};
