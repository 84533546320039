export default {
  APP_NAME: "Wow Admin",
  EN: "English",
  KO: "한국어",
  KH: "ខ្មែរ",
  LANGUAGE: "Language",
  USER_DETAILS: "User Details",
  CASINO_PRODUCT: "Casino Product (%)",
  SLOT_PRODUCT: "Slot Product (%)",
  SLOTS: "Slots",
  PREFIX: "Prefix",
  USERNAME: "Username",
  MA_NAME: "MA Name",
  PASSWORD: "Password",
  STATUS: "Status",
  LEVEL: "Level",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  CREATE: "Create",
  LOADING: "Loading",
  SUPER_MASTER_AGENT: "Super Master Agent",
  MASTER_AGENT: "Master Agent",
  AGENT: "Agent",
  LOGOUT: "Logout",
  CHANGE_PASSWORD: "Change Password",
  ACCOUNT_LIST: "Account List",
  FILTER: "Filter",
  INVALID_DATA: "Invalid Data!",
  ALL: "All",
  OPEN: "Open",
  CLOSED: "Closed",
  SUBMIT: "Submit",
  YES: "Yes",
  NO: "No",
  LOCK: "Lock",
  UNLOCK: "Unlock",
  LEAVE_BLANK_IF_NO_CHANGE: "Please leave field blank if no changes required",
  EDIT: "Edit",
  USERNAME: "Username",
  AG: "AG",
  SUSPEND: "Suspend",
  NAME: "Name",
  LAST_LOGIN_TIME: "Last Login Time",
  LAST_LOGIN_IP: "Last Login IP",
  NATION: "Nation",
  REGISTERED_TIME: "Registered Time",
  MEMO: "Memo",
  AGENT_PREFIX: "AG Prefix",
  NO_RECORD_FOUND: "No record(s) found!",
  COUNTRY: "Country",
  MEMBER_LIST: "Member List",
  ID: "ID",
  MEMBER_ID: "Member ID",
  ACTION: "Action",
  KICK_PLAYER: "Kick Player",
  PLAYABLE: "Playable",
  URL: "URL",
  API_LIST: "API List",
  METHOD: "Method",
  DESCRIPTION: "Description",
  COPIED: "COPIED",
  SEARCH: "Search",
  MAILBOX: "Mailbox",
  CREATE_MAIL: "Create Mail",
  WRITE_YOUR_MESSAGE_HERE: "Write your message here...",
  SUBJECT: "Subject",
  SEND: "Send",
  DATE: "Date",
  UPDATE: "Update",
  PLEASE_FILL_OUT_THE_FORM: "Please fill out the form.",
  HOME: "Home",
  MEMBER_MGMT: "Member Mgmt",
  DEPOSIT: "Deposit",
  DEPOSIT_INFO: "Deposit Info",
  REPORTS: "Reports",
  ACCOUNT: "Account",
  SIGN_OUT: "Sign Out",
  NEW_MA: "New MA",
  KEY_GRAPH: "Key Graph",
  INFORMATION: "Information",
  MAINTENANCE_STATUS: "Maintence Status",
  CASH_BALANCE: "Cash Balance",
  TOTAL_BALANCE: "Total Balance",
  TOTAL_MA : "Total MA",
  TOTAL_AG: "Total AG",
  TOTAL_MEMBER: "Total Member",
  FILTER: "Filter",
  INVALID_PREFIX: "Invalid prefix",
  USER_ID: "User ID",
  PROFILE: "Profile",
  API_KEY: "API Key",
  AG_CODE: "AG Code",
  AG_ID: "AG ID",
  ENABLE: "Enable",
  DISABLE: "Disable",
  PLAYING: "Playing",
  SELECT_GAME: "select game",
  SUMMARY: "Summary",
  REPORT:"Report",
  TOTAL: "Total",
  WINLOSS: "Winloss",
  WAGER: "Wager",
  TURNOVER: "Turnover",
  RTP: "RTP",
  HISTORY:"History",
  PRODUCT_ID:"Product ID",
  REFERENCE:"Reference",
  BALANCE:"Balance",
  CLOSING_BALANCE:"Closing Balance",
  GAME_ID:"Game ID",
  NO_OF_PAYLINES:"No. of Paylines",
  BET_TYPE:"Bet Type",
  WIN_LINES:"Win Lines",
  BET:"Bet",
  WIN : "Win",
  LOSE: "Lose",
  CANCEL: "Cancel",
  REFERENCE: "Reference",
  ONLINE: "Online",
  OFFLINE: "Offline",
  TOTAL_BET: "Total Bet",
  LIVE_REPORT: "Live Report",
  ROUND_ID: "Round ID",
  ROUND_DETAIL: "Round Details",
  PENDING: "Pending",
  MORE: "More",
  EXPORT: "Export",
};
