import react,{useState,useEffect} from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import constants from "../../helpers/constants";
import {useAuth} from '../../hooks/auth';
import HTTPService from "../../services/HTTPService"
const Info = ({data}) => {
  const API_URL = process.env.REACT_APP_API_SERVER;
  const {info} = useAuth();
  const {t} = useTranslation();
  const [totalSMA,setSMA] = useState(0);
  const [totalMA,setMA] = useState(0);
  const [totalAG,setAG] = useState(0);
  const [totalMEM,setMEM] = useState(0);
  const {userTypeAsString} = constants;

  const fetchData = async (url,param,header,cb) => {
    let res = await HTTPService.get(url,param,header);
     cb(null,res);
  }

  useEffect(() => {
    fetchData(API_URL,data.apiParam,{"api-key": info.api_key},(err,res) => {
        if(err) throw err;
        let datas = res.data;
        if(res && res.data && datas.length) {
          datas.map((item) => {

            if (userTypeAsString[item['level']] == 'sma') {
              setSMA(item['count']);
            } else if(userTypeAsString[item['level']]  == 'ma') {
              setMA (item['count']);
            } else if(userTypeAsString[item['level']]  == 'ag') {
              setAG (item['count']);
            }

          });
        }
    });
  },[])


  const dataSource = [
    {
      key: 'username',
      name: t("USERNAME"),
      value: data.username,
    },
    {
      key: 'cash-balance',
      name: t("CASH_BALANCE"),
      value: "000,000,000",
    },
    {
      key: 'total-balance',
      name: t("TOTAL_BALANCE"),
      value: "000,000,000",
    }
  ];

  const SMA = {
    key: 'total-sma',
    name: "SMA",
    value: totalSMA,
  }

  const MA = {
    key: 'total-ma',
    name: t("TOTAL_MA"),
    value: totalMA,
  }

  const AG = {
    key: 'total-ag',
    name: t("TOTAL_AG"),
    value: totalAG,
  }

  const MEM = {
    key: 'total-memt',
    name: t("TOTAL_MEMBER"),
    value: totalMEM,
  }

  if(userTypeAsString[data.level]        == 'admin') {
    dataSource.push(SMA,MA,AG,MEM);
  } else if (userTypeAsString[data.level] == 'sma') {
    dataSource.push(MA,AG,MEM);
  } else if(userTypeAsString[data.level]  == 'ma') {
    dataSource.push(AG,MEM);
  } else if(userTypeAsString[data.level]  == 'ag') {
    dataSource.push(MEM);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',

    },
    {
      title: 'value',
      dataIndex: 'value',
      key: 'value',
    }
  ];

  return (
   <Table bordered={true} showHeader={false} dataSource={dataSource} columns={columns} pagination={{hideOnSinglePage:true}} ></Table>
  );
}

export default Info;
