import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

export default {
  get(url, params, headers) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url, { params, headers })
          .then((response) => {
            resolve(response && response.data);
          })
          .catch((err) => {
            throw err;
          });
      } catch (err) {
        reject(err);
      }
    });
  },

  post(url, params, headers) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(url, params, { headers })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            throw err;
          });
      } catch (err) {
        reject(err);
      }
    });
  }
};
