import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import io from "socket.io-client";

import LiveDatetime from "../LiveDatetime";
import SelectLanguage from "../SelectLanguage";
import SLAvatar from "../../components/avatar/avatar";
import MailNotification from "../../components/MailNotification";
import constants from "../../helpers/constants";
import { useAuth } from "../../hooks/auth";
import config from "../../config";

import "./Header.scss";

const getMailboxStorage = () => {
  let mailStorage = window.localStorage.getItem("unread_mail");

  try {
    mailStorage = JSON.parse(mailStorage);
  } catch (err) {
    mailStorage = {};
  }

  mailStorage = mailStorage || {};
  return mailStorage;
};

const setMailboxStorage = (mailbox) => {
  window.localStorage.setItem("unread_mail", JSON.stringify(mailbox));
}

const Header = () => {
  const { t } = useTranslation();
  const { info: user } = useAuth();
  const currentLanguage = window.sessionStorage.getItem("current_language") || "en";
  const [isAvatarShow, setAvatarShow] = useState(false);
  const [isLanguageShow, setLanguageShow] = useState(false);
  const [socket, setSocket] = useState(null);
  const [mails, updateMails] = useState(() => {
    const mailStorage = getMailboxStorage();
    return (mailStorage && mailStorage[user.username]) || [];
  });

  useEffect(() => {
    const url = config.app.serverUrl.replace("http:", "");
    const newSocket = io(url, { query: `ssid=${sessionStorage.getItem("sid")}` });

    setSocket(newSocket);

    newSocket.on("message", (message) => {
      if (message.error) return;

      switch (message.e) {
        case constants.SOCKET_IO_MESSAGE_TYPE.NEW_MAIL:
          let receiver = message && message.receiver;

          if (receiver === user.username) {
            const newMail = {
              name: message.name,
              subject: message.subject,
              message: message.message,
              sender: message.sender,
              receiver,
            };

            updateMails((oldMails) => {
              const newMails = [...oldMails, newMail];
              const mailStorage = getMailboxStorage();

              mailStorage[receiver] = newMails;

              setMailboxStorage(mailStorage);

              return newMails;
            });
          }

          break;

        default:
          break;
      }
    });

    return () => newSocket.close();
  }, []);

  const showAvatarList = (value) => {
    if (isLanguageShow) setLanguageShow(false);
    setAvatarShow(value);
  };

  const showLanguageList = (value) => {
    if (isAvatarShow) setAvatarShow(false);
    setLanguageShow(value);
  };

  const handleMailNotificationClick = () => {
    const mailStorage = getMailboxStorage();

    mailStorage[user.username] = '';
    setMailboxStorage(mailStorage);
    updateMails([]);
  }

  return (
    <header className="app-header">
      <h3>{t('APP_NAME')}</h3>
      <LiveDatetime className="app-header__date" />
      <div className="app-header__right">
        <SLAvatar isOpen={isAvatarShow} setOpen={showAvatarList}></SLAvatar>
        {/* <MailNotification numberOfMails={mails.length} onClick={handleMailNotificationClick} /> */}
        {/* <SelectLanguage defaultValue={currentLanguage} isOpen={isLanguageShow} setOpen={showLanguageList} /> */}
      </div>
    </header>
  );
};

export default Header;
