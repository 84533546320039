import React from "react";
import { Switch, Select } from "antd";

import "./MAProduct.scss";

const MAProduct = ({ product, isImageOnly }) => {
  const handleChange = () => {

  }

  return (
    <div className="product">
      <div className="image-wrapper">
        <img src={product.img_path} alt={product.name} />
      </div>
      {!isImageOnly && (
      <>
        <Switch />
        <Select defaultValue="0" style={{ width: 80 }} onChange={handleChange}>
          <option key="0" value="0" disabled>0</option>
          {
            [...Array(95).keys()].map((item) => {
              const value = item+1;
              return <option key={value}>{value}</option>
            })
          }
        </Select>
      </>)}
    </div>
  );
};

export default MAProduct;
