/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Space, Typography, Card } from "antd";
import SLTable from "../../components/SLTable/SLTable";
import SLBreadcrumb from "../../components/Breadcrumb";
import "./SymbolList.scss";
import State from "../../data";

const SymbolList = () => {
  const history = useHistory();
  const [symbols, updateSymbols] = useState(State.symbols);

  const handleAddSymbolButtonOnClick = () => history.push("/symbols/add");
  const handleConfigureSymbolsButtonOnClick = () => history.push("/symbols/dis-symbols");

  const handleDeleteRow = useCallback((row) => {
    updateSymbols(() => [...symbols.filter((symbol) => row.symbol.name !== symbol.name)]);
  });

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 80,
      render: (text) => text
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (text) => text
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (data) => (
        <Space size="small">
          <Typography.Link onClick={() => handleDeleteRow(data)}>Delete</Typography.Link>
        </Space>
      )
    }
  ];

  const data = [];

  for (let i = 0; i < symbols.length; i++) {
    data.push({
      key: i + 1,
      no: i + 1,
      name: symbols[i].name,
      action: { symbol: symbols[i] }
    });
  }

  return (
    <div>
      {/* <PageHeader className="site-page-header" title="Symbol List" /> */}

      <section className="button-group">
        <Button type="primary" onClick={handleAddSymbolButtonOnClick}>
          Add a symbol
        </Button>
        <Button type="primary" onClick={handleConfigureSymbolsButtonOnClick}>
          Configure symbols
        </Button>
      </section>
      <SLBreadcrumb items={["Symbol"]} />
      <section>

        <Card>

          <SLTable bordered size="small" columns={columns} data={data} pagination={{ pageSize: 20, position: ["bottomLeft"] }} />
        </Card>
      </section>
    </div>
  );
};

export default SymbolList;
