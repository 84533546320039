import React from "react";
import { Route,
  Redirect } from "react-router-dom";

function PrivateRoute({ component, isAuthenticated, path, ...rest }) {
  return (
    <Route
      path={path}
      {...rest}
      component={
        isAuthenticated
          ?
          component
          : (
            ({ location }) => (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            )
          )
  }
    />
  );
}

export default PrivateRoute;
