/* eslint-disable react/jsx-closing-bracket-location */

import React from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, Form, Input, Button } from "antd";

import "./AddSymbol.scss";

const AddSymbol = () => {
  const history = useHistory();
  const handleBackHistoryOnClick = () => history.go(-1);

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <PageHeader className="site-page-header" onBack={handleBackHistoryOnClick} title="Add Symbol" subTitle="" />

      <section style={{ paddingTop: "1em", width: "16em" }}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ padding: "0 1.4em" }}>
          <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input symbol name!" }]}>
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16
            }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </section>
    </div>
  );
};

export default AddSymbol;
