import { NavLink } from "react-router-dom";
import { MailFilled } from '@ant-design/icons';

import "./MailNotification.scss";

const MailNotification = ({ numberOfMails, onClick }) => {
  return (
    <NavLink className="app-mail-notification" to="/mailbox" onClick={onClick}>
      <MailFilled />
      {
        !!+numberOfMails && <span className="app-mail-notification__badge">{numberOfMails}</span>
      }
    </NavLink>
  );
}

export default MailNotification;