import { Avatar } from "antd";
import { UserOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/auth";
import constants from "../../helpers/constants";
import App from "../../App";

import "./style.scss"

const SLAvatar = ({ isOpen, setOpen }) => {
  const {userTypeAsString} = constants;
  const { info } = useAuth();
  const { t } = useTranslation();

  const items = [
    {
      label: t("PROFILE"),
      value: "cp",
      icon: <UserOutlined />,
      url: "/profile"
    },
    {
      label: t("CHANGE_PASSWORD"),
      value: "cp",
      icon: <LockOutlined />,
      url: "/accounts/change-pass"
    },
    {
      label: t("LOGOUT"),
      value: "lo",
      icon: <LogoutOutlined />,
      url: "/login"
    },
  ];

  const handleClick = (value) => {
    if (value === "lo") {
      window.location.pathname = "/login";
    }
  };

  return (
    <App.Dropdown
      title={<>{info.username} <b>({userTypeAsString[info.level].toUpperCase()})</b></>}
      caption={<>{info.username} <b>({userTypeAsString[info.level].toUpperCase()})</b></>}
      displayItem={
        <Avatar
          size={{ xs: 10, sm: 20, md: 30, lg: 50, xl: 50, xxl: 35}}
          icon={<UserOutlined />}
          />
      }
      list={items}
      onClickItem={handleClick}
      isOpen={isOpen}
      setOpen={setOpen} />
  );
}

export default SLAvatar;
