/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/react-in-jsx-scope */
import "./style.scss";
import { Input, Row, Col, DatePicker, Button, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import moment from "moment";

const { RangePicker } = DatePicker;


const Filter = ({
  onSearch, sData = [[{
    span: 9,
    key: "col-1",
    field: <RangePicker
      key="date-picker"
      size="small"
      defaultValue={[moment(), moment()]} />
  }, {
    span: 4,
    key: "col-2",
    field: <Input
      key="currency"
      placeholder="currency"
      size="small"
      onChange={(e) => {
        // setCurrency(e.target.value);
      }}
    />
  }, {
    span: 5,
    key: "col-3",
    field:
      <Button
        type="primary"
        size="small"
        onClick={onSearch}
        icon={<SearchOutlined />}>
        Search
      </Button>
  }
  ], []], onDateChange, onStatusChange
}) => {
  const [currency, setCurrency] = useState("");
  const [status, setStatus] = useState("all");

  function getCurrency(e) {
    setCurrency(e.target.value);
  }

  return (
    <div id="filter">
      <Card>
        {sData.map((rows, index) => (
          <Row gutter={[24, 24]} key={index}>
            {rows.map((row) => (
              <Col key={row.key} span={row.span}>{row.field}</Col>
            ))}
          </Row>
        ))}
      </Card>
    </div>
  );
};

export default Filter;
