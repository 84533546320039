import constants from "../helpers/constants";

const settings = {
  number_of_reels: 5,
  number_of_rows: 3,
  free_spins: [3, 4, 5],
  payline_board: {
    lineWidth: 2,
    boardColor: "#F0FAFC",
    lineColor: "#0C383F",
    dotColor: "#0062BD"
  }
};

const symbols = [
  { key: "9", name: "9" },
  { key: "10", name: "10" },
  { key: "A", name: "A" },
  { key: "J", name: "J" },
  { key: "K", name: "K" },
  { key: "Q", name: "Q" },
  { key: "HEART", name: "HEART" },
  { key: "DIA", name: "DIA" },
  { key: "FREE", name: "FREE" },
  { key: "WILD", name: "WILD" },
  { key: "BONUS", name: "BONUS" },
  { key: "SCATTER", name: "SCATTER" }
];

const distributedSymbols = [
  ["J", "A", "A", "A", "HEART"],
  ["DIA", "FREE", "FREE", "10", "FREE"],
  ["A", "10", "10", "9", "WILD"],
  ["FREE", "9", "BONUS", "DIA", "Q"],
  ["K", "DIA", "9", "J", "SCATTER"],
  ["9", "BONUS", "DIA", "FREE", "10"],
  ["HEART", "SCATTER", "K", "SCATTER", "J"],
  ["Q", "WILD", "J", "K", "9"],
  ["BONUS", "HEART", "SCATTER", "HEART", "WILD"],
  ["SCATTER", "Q", "HEART", "WILD", "J"],
  ["A", "Q", "Q", "SCATTER", "K"],
  ["A", "HEART", "A", "10", "Q"],
  ["A", "J", "10", "BONUS", "BONUS"],
  ["10", "WILD", "WILD", "Q", "A"],
  ["", "K", "", "", "A"],
  ["", "A", "", "", "A"],
  ["", "", "", "", "A"],
  ["", "", "", "", "A"]
];

const paytables = [
  {
    key: "A",
    payments: [0, 0, 10, 50, 200]
  },
  {
    key: "10",
    payments: [0, 0, 20, 60, 120]
  },
  {
    key: "9",
    payments: [0, 0, 10, 100, 50]
  },
  {
    key: "DIA",
    payments: [0, 5, 10, 50, 100]
  },
  {
    key: "J",
    payments: [0, 0, 20, 45, 120]
  },
  {
    key: "K",
    payments: [0, 0, 30, 60, 140]
  },
  {
    key: "HEART",
    payments: [0, 35, 70, 100, 1100]
  },
  {
    key: "Q",
    payments: [0, 0, 35, 70, 160]
  },
  {
    key: "FREE",
    payments: [0, 0, 0, 0, 0]
  },
  {
    key: "BONUS",
    payments: [0, 0, 0, 0, 0]
  },
  {
    key: "SCATTER",
    payments: [0, 0, 0, 0, 0]
  }
];

const paylines = [
  {
    type: constants.PaylineTab.LINE,
    lines: [
      [0, 0, 0, 0, 0],
      [1, 1, 1, 1, 1],
      [2, 2, 2, 2, 2],
      [0, 1, 2, 1, 0],
      [2, 1, 0, 1, 2],
      [0, 0, 1, 2, 2],
      [2, 1, 1, 1, 0],
      [1, 2, 2, 2, 1],
      [1, 0, 0, 0, 1],
      [2, 2, 1, 0, 0]
    ]
  },
  {
    type: constants.PaylineTab.SCATTER,
    lines: []
  }
];

export default { symbols, settings, distributedSymbols, paytables, paylines };
